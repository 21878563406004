<div
  class="slider-container"
  [ngStyle]="{
    height: itemHeight + 'px'
  }"
>
  <div class="slider-content" #sliderContent>
    <span
      class="slider-item"
      *ngFor="let item of items; let i = index"
      [ngStyle]="{
        'background-color': item.tag_color,
        padding: item.tag_color ? '0 5px' : '0px',
        height: itemHeight + 'px'
      }"
      [ngClass]="CommonService.isRtl ? 'text-right' : 'text-left'"
    >
      <img
        *ngIf="item.img"
        [src]="CommonService._ImageUrl + 'tags/' + item.img"
        [alt]="item.tag_value"
        [ngStyle]="{
          height: itemHeight - 5 + 'px'
        }"
      />
      <p
        [ngStyle]="{
          'font-size': itemStyle['font-size'],
          'font-weight': itemStyle['font-weight'],
          'line-height': itemHeight + 'px'
        }"
        [style.color]="
          item.tag_color ? calculateTextColor(item.tag_color) : '#000'
        "
      >
        {{ item.tag_value }}
      </p>
    </span>
  </div>
</div>
