import { CommonService } from "src/app/shared/services/common.service";
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Inject,
  ViewEncapsulation,
  TemplateRef,
  AfterViewInit,
  ViewChildren,
  QueryList,
  Renderer2,
  Optional,
  HostListener,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ProductDetailsMainSlider,
  ProductDetailsThumbSlider,
  ProductSlider,
} from "../../../../shared/data/slider";
import { Product } from "../../../../shared/classes/product";
import { ProductService } from "../../../../shared/services/product.service";
import { SizeModalComponent } from "../../../../shared/components/modal/size-modal/size-modal.component";
import { ParamMap, NavigationEnd } from "@angular/router";
import { Clipboard } from "@angular/cdk/clipboard";

import { UserService } from "../../../../shared/services/user.service";
import { ToastrService } from "ngx-toastr";
import { DOCUMENT, Location } from "@angular/common";

import {
  NgbModal,
  ModalDismissReasons,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { REQUEST } from "@nguniversal/express-engine/tokens";
import { Request } from "express";
import SwiperCore, {
  FreeMode,
  Navigation,
  Pagination,
  SwiperOptions,
  Thumbs,
} from "swiper";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { DomSanitizer, Meta, SafeHtml, Title } from "@angular/platform-browser";
import { type } from "os";
import { CategoriesService } from "src/app/shared/services/categories";
import { SwiperComponent } from "swiper/angular";
import { OwlOptions } from "ngx-owl-carousel-o";
import { HttpErrorResponse } from "@angular/common/http";
import { MasterApiService } from "src/app/shared/services/master-api.service";
import { Page, policyList } from "src/app/shared/classes/privacyList";
import { DirectRequestComponent } from "../direct-request/direct-request.component";
import { FormBuilder, FormGroup } from "@angular/forms";
import { distinctUntilChanged } from "rxjs/operators";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

// install Swiper modules
SwiperCore.use([FreeMode, Navigation, Thumbs, Pagination]);

declare var jquery: any;
declare var $: any;
// declare let fbq: Function;

@Component({
  selector: "app-product-left-sidebar",
  templateUrl: "./product-left-sidebar.component.html",
  styleUrls: ["./product-left-sidebar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ProductLeftSidebarComponent implements OnInit, OnDestroy {
  frontLang: string = localStorage.getItem("front-lang");
  @ViewChild("templateModalShipping", { static: false })
  templateModalShipping: TemplateRef<any>;
  @ViewChild("mainProductSwiper") mainProductSwiper?: SwiperComponent;
  @ViewChild("mainProductSwiper2") mainProductSwiper2?: SwiperComponent;
  @ViewChildren("privacyTitle") privacyTitle?: QueryList<any>;
  @ViewChildren("privacyTitleName") privacyTitleName?: QueryList<any>;
  @ViewChild("directRequestComponentLarge")
  directRequestComponentLarge!: DirectRequestComponent;
  @ViewChild("directRequestComponentSmall")
  directRequestComponentSmall!: DirectRequestComponent;
  @ViewChild("directRequestContainerLarge")
  directRequestContainerLarge!: ElementRef;
  @ViewChild("directRequestContainerSmall")
  directRequestContainerSmall!: ElementRef;
  isFavourit: boolean = false;
  thumbsSwiper?: any;
  productLoading: boolean = false;
  relatedProductsSliderConfig: SwiperOptions = {
    slidesPerView: "auto",
    spaceBetween: 20,
    // navigation: true,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
  };

  public ProductSliderConfig: any = ProductSlider;
  closeResult = "";

  private modalRef!: NgbModalRef;

  schema = {
    "@context": "http://schema.org",
    "@type": "Product",
    brand: {
      "@type": "Brand",
    },
    review: {
      "@type": "Review",
      author: {
        "@type": "Person",
        name: localStorage.getItem("front_name"),
      },
    },
    offers: {
      "@type": "Offer",
      itemCondition: "https://schema.org/NewCondition",
      availability: "https://schema.org/InStock",
    },
  };
  supportDetails: any;
  loaderSupport: boolean = true;
  noSupportContent: boolean;
  public product: any;
  public productimages: any[] = [];
  public counter: number = 1;
  public activeSlide: any = 0;
  public selectedSize: any;
  public mobileSidebar: boolean = false;
  public quantatyMorThanStor: boolean = false;
  public category_id = "";
  public relatedproducts: any[] = [];
  CitiesFiltered: any = [];

  public settings: any;

  public productFormShapesDetails: any = [];
  unitPricefor1quantity: string = "";
  quantity_discount: any = [];
  related_product: any = [];
  similar_product: any = [];
  multi_select: string[] = [];
  userLogedIn: boolean = false;
  deviceID: string;
  userNotLogedInError: boolean = false;
  showPrice: boolean = false;
  public productStors: any[] = [];
  public selectedStore: any = "";
  public errorselectedStore: boolean = false;
  public imagetrademarkurl = "";
  idtime: any;
  timesnum = 0;
  urlimage;
  importApiImage;
  imageurl = "";
  logourl = "";
  tabId = "general";

  // FREQUENTLY BOUGHT BLOCK VARIABLES
  freqBoughtCheckboxList: any[] = [false, false, false];

  // PACKAGE DATA
  packageData: any;
  newPackagePrice: any;
  selectedProductsCount: number = 0;
  totalPrice: number = 0;

  @Input("loadVideo") loadVideo: boolean = false;

  @ViewChild("sizeChart") SizeChart: SizeModalComponent;
  // @ViewChild("swiperContainer") swiperContainer: ElementRef;
  // @ViewChild("mainParent") mainParent: ElementRef;
  // @ViewChild("placeholder") placeholder: ElementRef;
  @ViewChild("privacylet", { static: false }) privacyModal: TemplateRef<any>;
  @ViewChild("templateModalDate", { static: false })
  templateModalDate: TemplateRef<any>;
  @ViewChild("imagesModal", { static: false }) imagesModal: TemplateRef<any>;

  @Input() currency: any = this.productService.Currency; // Default Currency
  public ProductDetailsMainSliderConfig: any = ProductDetailsMainSlider;
  public ProductDetailsThumbConfig: any = ProductDetailsThumbSlider;
  noteLoader: boolean = true;
  note_id: number;
  @ViewChild("templateBottomSheet") TemplateBottomSheet: TemplateRef<any>;
  description: string = "";
  keyWords: string = "";
  Title: string = "";
  imgUrl: string = "";
  brief_description: any;
  meta_description: string = "";
  meta_title: string = "";
  hostName: string = "";
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: [
      '<i class="fa fa-chevron-left"></i>',
      '<i class="fa fa-chevron-right"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      200: {
        items: 2,
      },
      400: {
        items: 3,
      },
    },
    nav: true,
  };

  ProductQuantity: number = 0;
  customer_service: any;
  imageurlCustomer: string;
  labelWhatAppUrl: string;
  countryInfo: any;
  privacyDetails: policyList = {
    pages: [
      {
        id: "0",
      },
    ],
  };
  supportIcon: string;
  loginclicked: boolean = false;
  mainUrlimage: string;
  originalName: string = "";
  bottomOffset: any;
  swiperContainerWidth: any;
  optionItemData: any;
  id_of_product: any;
  shipment_id: any;
  buyNowLoading: boolean = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private el: ElementRef,
    private route: ActivatedRoute,
    private clipboard: Clipboard,
    private toastrService: ToastrService,
    public user: UserService,
    private router: Router,
    public Common: CommonService,
    public productService: ProductService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private bottomSheet: MatBottomSheet,
    private meta: Meta,
    private location: Location,
    private sanitizer: DomSanitizer,
    private categories: CategoriesService,
    private title: Title,
    private MasterApiService: MasterApiService,
    public renderer: Renderer2,
    private _fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    @Optional() @Inject(REQUEST) private request: Request,
    private breakpointObserver: BreakpointObserver
  ) {
    this.productFormShapesDetails.loading = true;
    this.getAllContanctInfo();
    this.urlimage = this.Common._ImageUrl + "products/thumbs/";
    this.mainUrlimage = this.Common._ImageUrl + "products/";
    this.supportIcon = this.Common._ImageUrl + "commitments-and-policies/";
    this.importApiImage = this.Common._ImageUrl + "importapi/";
    this.imagetrademarkurl = this.Common._ImageUrl + "trademarks/";
    // this.route.data.subscribe(response => this.product = response.data );
    this.imageurl = this.Common._ImageUrl + "front_images/";
    this.imageurlCustomer = this.Common._ImageUrl + "customer-service/";
  }

  addPackageToCart(product1: any, product2: any, product3: any) {
    this.addToCart(product1);
    this.addToCart(product2);
    this.addToCart(product3);
    console.log(product1, product2, product3);
  }

  freqCheck(index) {
    this.freqBoughtCheckboxList[index] = !this.freqBoughtCheckboxList[index];
    // console.log("frequent checklist ", this.freqBoughtCheckboxList);
  }

  private scrollToComponent() {
    if (window.innerWidth > 768) {
      if (this.directRequestContainerLarge) {
        this.directRequestContainerLarge.nativeElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    } else {
      if (this.directRequestContainerSmall) {
        this.directRequestContainerSmall.nativeElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }

  checkFreqSelected(event, index) {
    if (event.currentTarget.checked) {
      this.freqBoughtCheckboxList[index] = true;
    } else {
      this.freqBoughtCheckboxList[index] = false;
    }
  }
  handleImageError(e) {
    e.src = "assets/images/product/placeholder2.jpg";
  }
  tabChange(id: any) {
    this.tabId = id;
  }
  transform(html: string): string {
    return html.replace(/<[^>]*>/g, "");
  }
  checkOuth() {
    if (this.user.getToken() !== null || this.deviceID) {
      // console.log(this.deviceID);
      // console.log(localStorage.getItem('Device-Id'));
      this.userLogedIn = true;
    }
    if (this.user.getShowPrice() == "1") {
      this.showPrice = true;
    }
  }

  breadCrumblist: any;
  breadCrumbProductName: string = "";
  optionsData: any = [];
  optionChekced: number = 0;
  isMobile: boolean = false;
  showDirectRequestComponent: boolean = true;
  breadCrumbSchema: any;
  ngOnInit(): void {
    this.renderer.setStyle(document.body, "line-height", "normal");

    // Observe screen size changes
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.isMobile = result.matches;
      });
    this.deviceID = localStorage.getItem("Device-Id");

    this.checkOuth();
    this.route.params.subscribe((params: ParamMap) => {
      // remove tag
      this.removeHomeMetaTags();

      const product_id = params["slug"];
      this.id_of_product = params["slug"];

      this.productService.getProductTitle(product_id).subscribe((data) => {
        if (!data.success) {
          this.router.navigate(["/not-found"]);
          return;
        }
        console.log(data);

        this.meta_description = data.data.meta_description
          ? this.transform(data.data.meta_description)
          : this.transform(data.data.description);
        this.meta_title = data.data.meta_title
          ? data.data.meta_title
          : data.data.name;
        this.Common.setMeta("", this.meta_description, true, "og:description");
        this.Common.setMeta("", this.meta_title, true, "og:title");
        this.Common.setMeta("description", this.meta_description);
        this.Common.setMeta("keywords", data.data.key_words);
        this.Common.removeLinkTags(this.renderer);

        this.hostName = this.Common.getDomainFromUrl();
        console.log("hostName", this.hostName);
        this.Common.addLinkTag(
          this.renderer,
          "canonical",
          `https://${this.hostName}/shop/product/${product_id}/${
            data.data.meta_url ? data.data.meta_url : data.data.name
          }`
        );
        this.Common.addLinkTag(
          this.renderer,
          "alternate",
          `https://${this.hostName}/shop/product/${product_id}/${data.data.name_ar}`,
          "hreflang",
          "ar"
        );
        this.Common.addLinkTag(
          this.renderer,
          "alternate",
          `https://${this.hostName}/shop/product/${product_id}/${data.data.name_en}`,
          "hreflang",
          "en"
        );
        this.Common.addLinkTag(
          this.renderer,
          "alternate",
          `https://${this.hostName}/shop/product/${product_id}/${data.data.name_ar}`,
          "hreflang",
          "x-default"
        );
      });
      this.getCommitmentsAndPoliciesByMode();

      if (this.product) {
        this.productLoading = false;
      } else {
        this.productLoading = true;
      }

      this.productService.getProductDetails(product_id).subscribe((data) => {
        if (!data.success) {
          this.router.navigate(["/not-found"]);
          return;
        }
        this.productLoading = false;
        // Loadding Off
        this.product = data["data"];

        this.category_id = this.product["product_services"][0]["service_id"];
        this.breadCrumbProductName = this.product["name"].split(" ").join(" ");

        //getBreadcrumb
        this.categories.getBreadcrumb(this.category_id).subscribe((cate) => {
          if (cate.success) {
            this.breadCrumblist = cate.data;
            this.breadCrumbSchema = {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Home",
                  item: `https://${this.hostName}/`,
                },
                ...this.breadCrumblist.map((breadCrumb, index) => ({
                  "@type": "ListItem",
                  position: index + 2,
                  name: breadCrumb.ser_name,
                  item: `https://${this.hostName}/shop/category/${
                    breadCrumb.id
                  }/${breadCrumb.ser_name.split(" ").join("-")}`,
                })),
                {
                  "@type": "ListItem",
                  position: this.breadCrumblist.length + 2,
                  name: this.breadCrumbProductName,
                  item: `https://${
                    this.hostName
                  }/shop/product/${product_id}/${this.breadCrumbProductName
                    .trim()
                    .split(" ")
                    .join("-")}`,
                },
              ],
            };

            console.log("breadCrumbSchema", this.breadCrumbSchema);
          }
        });

        this.Common.UpdatedCartProducts$.subscribe((id) => {
          if (this.product["id"] == id) {
            this.product.quantity_in_cart = "0";
            this.Common.UpdatedCartProducts$.next("");
          }
        });
        this.optionsData = data.data.stores;
        // if (this.optionsData.length > 1) {
        //   this.optionsData = this.optionsData.filter(
        //     (element) => element.name !== "الافتراضي"
        //   );
        // }
        if (this.optionsData.length > 0) {
          this.optionItemData = this.optionsData[0];
        } else {
          this.optionItemData = [];
        }
        this.quantity_discount = [];
        this.related_product = [];
        this.similar_product = [];
        this.ProductQuantity = 0;
        this.Common.setTitle(
          this.product.meta_title ? this.product.meta_title : this.product.name,
          false,
          false,
          "product"
        );

        this.title.setTitle(
          this.product.meta_title
            ? localStorage.getItem("front_name") +
                " - " +
                this.product.meta_title
            : localStorage.getItem("front_name") + " - " + this.product.name
        );
        this.Title = !this.product.meta_title
          ? this.product.name
          : this.product.meta_title;

        this.brief_description = this.product?.brief_description;
        // console.log(this.product.name.split(' ').join('-'));

        if (this.product.images.length > 0) {
          this.productimages = this.product.images;
          this.imgUrl = this.urlimage + this.productimages[0].original_name;
        }
        // GET PACKAGE DATA
        if (this.product["package_data"]) {
          this.packageData = this.product["package_data"];
          // console.log("PRODUCT DATA package data", this.packageData);
          this.newPackagePrice =
            this.packageData.total_price * 1 - this.packageData.discount * 1;
        }

        this.schema["name"] = this.product["name"];

        if (this.product["images"].length) {
          this.schema["image"] =
            this.urlimage + this.product["images"][0]["name"];
        }

        this.schema["sku"] = this.product["product_barcode"];

        this.schema["brand"]["name"] = this.product["trademark_title"];
        this.schema["brand"]["logo"] =
          this.imagetrademarkurl + this.product["trademark_image"];
        this.schema["description"] = this.product["description_strip_tags"];
        this.schema["price"] = this.product["price"];
        this.schema["last_price"] = this.product["last_price"];
        this.schema["offers"]["price"] = this.product["price"];
        this.schema["offers"]["priceCurrency"] = this.settings.front_currency;
        this.schema["offers"]["url"] = `https://${
          this.hostName
        }/shop/product/${product_id}/${
          data.data.meta_url ? data.data.meta_url : data.data.name
        }`;
        console.log("schema", this.schema);

        this.description =
          this.product["meta_description"] == null
            ? this.product["description"]
            : this.product["meta_description"];
        this.keyWords = this.product["key_words"];
        this.productFormShapesDetails = this.product["form_shapes_Details"];
        this.getProductsRelated(this.product["id"]);
        if (this.product["store_amounts"] > 0 && +this.product["price"] > 0) {
          this.getProductQuantityDiscount(this.product["id"]);
          this.getProductSimilar(this.product["id"]);
        }

        for (let i = 0; i < this.productFormShapesDetails.length; i++) {
          if (this.productFormShapesDetails[i].type == "m_select") {
            this.multi_select[i] =
              this.productFormShapesDetails[i].form_value.split("-");
          }
        }
        console.log(this.productFormShapesDetails);
        this.productFormShapesDetails.loading = false;

        // SET TITLE
        const currentUrl = this.router.url.split("/");
        currentUrl[4] =
          this.product.meta_url == null
            ? this.removeBrackets(this.product.name)
            : this.product.meta_url.split(" ").join("-");
        const totalUrl = currentUrl.join("/");
        const baseUrl = window.location.origin;

        //console.log(totalUrl);

        //console.log(desc);
        //console.log(this.product['meta_description']);

        const fullUrl = baseUrl + totalUrl;
        //console.log(fullUrl);

        // this.Common.setMeta("description",desc );
        // this.Common.setMeta("keywords", this.keyWords);
        //this.Common.setMeta("twitter:url", fullUrl);

        this.Common.setMeta("", this.document.URL, true, "og:url");
        //this.Common.setMeta("twitter:title",this.Common.mainTitle + ' - ' + this.Title);
        //this.Common.setMeta("twitter:description",this.description);
        this.Common.setMeta("", this.imgUrl, true, "og:image");
        this.Common.setMeta("", "product", true, "og:type");

        // this.meta.addTag({name : 'keywords' , content : this.keyWords });
        // this.meta.addTag({name : 'description' , content : desc });
        this.meta.addTag({
          property: "product:price:amount",
          content: this.product["price"].toString(),
        });
        this.meta.addTag({
          property: "product:price:currency",
          content: this.Common.currencyType,
        });
        this.meta.addTag({
          property: "product:sale_price:amount",
          content: this.product["price"].toString(),
        });
        this.meta.addTag({
          property: "product:sale_price:currency",
          content: this.Common.currencyType,
        });
        this.meta.addTag({
          property: "product:brand",
          content:
            this.product["trademark_title"] == null
              ? ""
              : this.product["trademark_title"],
        });
        this.meta.addTag({
          property: "product:retailer_item_id",
          content: this.product["id"].toString(),
        });
        this.meta.addTag({
          property: "product:availability",
          content:
            this.product["store_amounts"] > 0 ? "in stock" : "out of stock",
        });

        this.productStors = this.product["stores"].filter(
          (ele) => ele.store_amounts_product > 0
        );

        // console.log("THIS ARE THE PRODUCTS", this.productStors);

        if (this.productStors.length == 1) {
          this.product["product_type_id"] =
            this.productStors[0]["product_type_id"];
          this.selectedStore = this.productStors[0]["product_type_id"];
        }

        if (this.category_id != "") {
          this.getRelatedProduct(this.category_id);
        }
      });

      this.productService
        .addBrowseProductHistory(product_id)
        .subscribe((data) => {
          // console.log("browse ProductHistory", data);
        });
    });
    this.Common.directRequest$
      .pipe(
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        )
      )
      .subscribe((data) => {
        console.log("data", data);
        if (Object.keys(data).length > 0) {
          localStorage.setItem("shipment_id", data.shipment_id);
          this.onChangeCity(data.city_id);
        }
      });
  }

  getProductQuantityDiscount(Product_id): void {
    this.productService.getProductQuantityDiscount(Product_id).subscribe({
      next: (res) => {
        if (res.success) {
          console.log(res);
          this.quantity_discount = res.data;
          // this.quantity_discount.sort(
          //   (a, b) => parseInt(a.quantity) - parseInt(b.quantity)
          // );
          console.log("quantity_discount", this.quantity_discount);
          this.quantity_discount.forEach((element) => {
            if (parseInt(element.quantity) == 1) {
              this.unitPricefor1quantity = element.unit_price;
              console.log(this.unitPricefor1quantity);
            }
          });
          if (this.quantity_discount.length > 0) {
            for (
              let index = 0;
              index < this.quantity_discount.length;
              index++
            ) {
              if (
                parseInt(this.quantity_discount[index].quantity) <=
                  this.product.stores[0]["store_amounts_product"] &&
                parseInt(this.quantity_discount[index].quantity) > 1
              ) {
                this.ProductQuantity++;
              }
            }
          }
        }
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      },
    });
  }
  getProductSimilar(Product_id): void {
    this.productService.getProductSimilar(Product_id).subscribe({
      next: (res) => {
        if (res.success) {
          console.log(res);
          this.similar_product = res.data;
          this.similar_product.forEach((product) => {
            product.checked = true;
          });

          this.calculateTotalPrice();
          console.log("similar_product", this.similar_product);
        }
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      },
    });
  }
  getProductsRelated(Product_id): void {
    this.productService.getProductsRelated(Product_id).subscribe({
      next: (res) => {
        if (res.success) {
          console.log(res);
          this.related_product = res.data;
          console.log("related_product", this.related_product);
        }
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      },
    });
  }
  removeBrackets(str: string): string {
    return str.replace(/[\(/%)]/g, "");
  }
  // removeBrackets(str: string): string {
  //   if (str != null) {
  //     return str?.replace(/[\()/%]/g, "");
  //   }
  // }
  removeHomeMetaTags() {
    this.meta.removeTag('name="description"');
    this.meta.removeTag('name="keywords"');
    this.meta.removeTag('property="og:description"');
    this.meta.removeTag('property="og:title"');
    // this.meta.removeTag('name="twitter:title"');
    // this.meta.removeTag('name="twitter:description"');
    // this.meta.removeTag('name="twitter:url"');
    this.meta.removeTag('property="og:url"');
    this.meta.removeTag('property="product:pretax_price:currency"');
    this.meta.removeTag('property="product:price:amount"');
    this.meta.removeTag('property="product:price:currency"');
    this.meta.removeTag('property="product:sale_price:amount"');
    this.meta.removeTag('property="product:sale_price:currency"');
    this.meta.removeTag('property="product:brand"');
    this.meta.removeTag('property="product:retailer_item_id"');
    this.meta.removeTag('property="product:availability"');
    this.meta.removeTag('property="og:image"');
  }

  openNotifyMeModal(content, productId) {
    // this.cartModal = true;

    if (localStorage.getItem("clientToken")) {
      // console.log("product id notify modal ", productId);
      // this.productIdToListenToAvailability = productId;

      this.modalService
        .open(content, { ariaLabelledBy: "modal-basic-title" })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    } else {
      this.toastrService.warning(
        this.translateService.instant(
          "Please log in or register to get notified when this product is available"
        )
      );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  getAllContanctInfo() {
    // this.Common.getAllContanctInfo().subscribe((data) => {
    //   //console.log'all contact info ', data);
    //   if (data.success) {
    //     this.settings = data.data;
    //     this.logourl = this.imageurl + this.settings["front_logo"];
    //     if (this.settings.full_description_web != "1") {
    //       this.tabId = "description";
    //     }
    //     localStorage.setItem(
    //       "paymentInfo",
    //       JSON.stringify(this.Common.getAllPaymentMethods(data.data))
    //     );
    //   }
    // });

    this.MasterApiService.getCurrentDataContact().subscribe(
      (dataContact) => {
        if (!dataContact) {
          console.error("Data contact information is undefined or null");
          return;
        }

        this.settings = dataContact;
        this.country_id = dataContact.country_info.id;
        this.onChangeCountry(this.country_id);
        this.customer_service = this.settings["customer_service"];
        this.countryInfo = this.settings["country_info"];
        this.labelWhatAppUrl =
          this.Common._ImageUrl +
          "customer-service/" +
          this.customer_service["product_page"][0]?.img;

        this.logourl = this.imageurl + this.settings["front_logo"];
        if (this.settings.full_description_web != "1") {
          this.tabId = "description";
        }
        localStorage.setItem(
          "paymentInfo",
          JSON.stringify(this.Common.getAllPaymentMethods(dataContact))
        );
      },
      (error) => {
        console.error("Error fetching contact info", error);
      }
    );
  }

  getRelatedProduct(category_id) {
    this.productService
      .getProductsPayParams(category_id)
      .subscribe((products) => {
        //console.log(products);

        if (products.data) {
          this.relatedproducts = products.data;
          // console.log("related products", this.relatedproducts);
        } else {
          // console.log("no data in related products");
        }
      });
  }

  shareViaWhatapp(product_page: any) {
    const baseUrl = "https://wa.me/";
    const phoneNumber = product_page?.value;
    const currentUrl = window.location.origin + this.router.url;
    // const encodedUrl = encodeURIComponent(currentUrl);
    let WUrl = `${baseUrl}${phoneNumber}?text=${this.product.name}%0A${currentUrl}`;
    window.open(WUrl, "_blank");
  }
  showPrivacyDetails(id: string, originalName = "") {
    this.showSupportCategory(id);
    this.originalName = originalName;
    document.querySelector("html")?.classList.add("no-scroll");

    this.modalRef = this.modalService.open(this.privacyModal, {
      size: "lg",
      ariaLabelledBy: "privacyModal-Modal",
      centered: true,
      windowClass:
        "theme-modal newsletterm NewsLetterModal modal-support no-scroll",
      scrollable: true,
      backdrop: true,
      keyboard: true,
    });

    this.modalRef.result.finally(() => {
      document.querySelector("html")?.classList.remove("no-scroll");
    });
  }

  showSupportCategory(id: string) {
    this.loaderSupport = true;
    if (this.supportDetails?.description) {
      this.supportDetails["description"] = "";
    }
    this.Common.getSupportPageById(id).subscribe({
      next: (data) => {
        console.log(data);
        if (data?.success) {
          this.supportDetails = data.data;
          this.loaderSupport = false;
        }
        this.loaderSupport = false;
        this.noSupportContent = true;
      },
      error: (err) => {
        console.log(err);
        this.loaderSupport = false;
        this.noSupportContent = true;
      },
    });
  }
  getCommitmentsAndPoliciesByMode() {
    this.Common.getCommitmentsAndPoliciesByMode(
      "show_in_product_page"
    ).subscribe({
      next: (res) => {
        console.log("show_in_product_page", res);
        if (res?.success) {
          this.privacyDetails?.pages.shift();
          this.privacyDetails = res?.data;
          setTimeout(() => {
            const gradient = `linear-gradient(${this.privacyDetails?.color_deg}, ${this.privacyDetails?.color_1}, ${this.privacyDetails?.color_2})`;
            console.log(this.privacyTitle);
            this.privacyTitle?.forEach((ele: ElementRef) => {
              this.renderer.setStyle(
                ele?.nativeElement,
                "background",
                gradient
              );
              this.setFontColorBasedOnBackground(
                ele?.nativeElement.children[0]
              );
            });
          }, 300);
        } else {
          this.privacyDetails?.pages.shift();
        }
      },
      error: (err) => {
        console.log(err);
        this.privacyDetails?.pages.shift();
      },
    });
  }

  isColorDark(color: string): boolean {
    let r: number = 0,
      g: number = 0,
      b: number = 0;

    if (color.match(/^#([0-9a-f]{3}){1,2}$/i)) {
      let hex = color.substring(1);
      if (hex.length === 3) {
        hex = hex
          .split("")
          .map((h) => h + h)
          .join("");
      }
      const num = parseInt(hex, 16);
      r = (num >> 16) & 255;
      g = (num >> 8) & 255;
      b = num & 255;
    } else if (color.match(/^rgb/i)) {
      const rgbValues = color.match(/\d+/g);
      if (rgbValues) {
        r = parseInt(rgbValues[0], 10);
        g = parseInt(rgbValues[1], 10);
        b = parseInt(rgbValues[2], 10);
      }
    } else {
      throw new Error("Invalid color format");
    }

    // Calculate brightness using the formula
    // Brightness formula: (0.299*R + 0.587*G + 0.114*B)
    const brightness = 0.299 * r + 0.587 * g + 0.114 * b;

    // Return true if brightness is below a threshold (e.g., 128), indicating a dark color
    return brightness < 128;
  }

  setFontColorBasedOnBackground(element: HTMLElement): void {
    console.log(element);
    const backgroundColor = this.privacyDetails?.color_1;
    console.log(backgroundColor);
    if (this.isColorDark(backgroundColor)) {
      element.style.color = "white";
    } else {
      element.style.color = "black";
    }
  }
  getDiscountPercentage(last_price, price) {
    var l_p = parseInt(last_price);
    var p = parseInt(price);
    var res = ((l_p - p) / l_p) * 100;
    return Math.round(res);
  }

  // Get Product Size
  Size(variants) {
    const uniqSize = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqSize.indexOf(variants[i].size) === -1 && variants[i].size) {
        uniqSize.push(variants[i].size);
      }
    }
    return uniqSize;
  }

  selectSize(size) {
    this.selectedSize = size;
  }

  // Increament
  increment() {
    if (this.counter < this.optionItemData["store_amounts_product"])
      this.counter++;
  }

  // Decrement
  decrement() {
    if (this.counter > 1) this.counter--;
    // this.Common.UpdatedSetting.next("decrement");
  }

  // ADD TO CART 1
  addToCart1(product: any) {
    this.productService.addToCart1(product);
  }

  chooseOptions(index, product) {
    this.counter = 1;
    this.optionChekced = index;
    this.optionItemData = product;
    console.log(this.optionItemData);
  }

  // Add to cart
  async addToCart(product: any) {
    product.isAddToCart = true;
    console.log("new product", product);

    let selectedStore = "";
    let selectedStoreName = "";
    let errorselectedStore;
    let quantatyMorThanStor;
    if (this.userLogedIn == true) {
      errorselectedStore = false;
      quantatyMorThanStor = false;
      // let productStors = product["stores"].filter(
      //   (ele) => ele.store_amounts_product > 0
      // );

      if (this.optionItemData) {
        // product["product_type_id"] = productStors[0]["product_type_id"];
        // selectedStore = productStors[0]["product_type_id"];
        product["store_id"] = this.optionItemData["store_id"];
        selectedStore = this.optionItemData["store_id"];
        selectedStoreName = this.optionItemData["name"];
      }
      console.log(selectedStore, selectedStoreName);
      if (selectedStore == "") {
        console.log("Product Add To Cart => ", product);
        if (localStorage.getItem("front-lang") == "en") {
          this.toastrService.error("Product not available");
        } else {
          this.toastrService.error(" المنتح غير متوفر ");
        }
        errorselectedStore = true;
      } else {
        product["selected_store"] = true;
        product["store_id"] = selectedStore;
        product.quantity = this.counter > 1 ? this.counter : 1;
        console.log("Product Quantity " + product.quantity);
        for (let i = 0; i < this.optionsData.length; i++) {
          if (
            this.optionsData[i].store_id == selectedStore &&
            this.optionsData[i].name == selectedStoreName
          ) {
            if (
              product.quantity > this.optionsData[i]["store_amounts_product"]
            ) {
              quantatyMorThanStor = true;
              if (localStorage.getItem("front-lang") == "en") {
                this.toastrService.error("Quantity is not for this item");
              } else {
                this.toastrService.error("الكمية غير متاحة لهذا الصنف");
              }
              errorselectedStore = true;
              return;
            }
          }
        }
        product["stores"] = this.optionsData;
        const toast = await this.productService.addToCart(
          product,
          this.optionItemData
        );

        if (toast) {
          if (localStorage.getItem("front-lang") == "en") {
            this.toastrService.success("Product has been added in cart.");
          } else {
            this.toastrService.success("تم إضافة المنتج الى السلة ");
          }
          product.quantity_in_cart += 1;
          product.isAddToCart = false;
        }
        return true;
      }
    } else {
      this.toastrService.error("يرجى تسجيل الدخول");
      this.userNotLogedInError = true;
      return false;
    }
  }

  // Buy Now
  async buyNow(product: any) {
    if (this.buyNowLoading) return;
    this.buyNowLoading = true;

    if (this.user.getToken() == null) {
      this.scrollToComponent();
      if (window.innerWidth > 768) {
        this.directRequestComponentLarge.focusFirstInput();
      } else {
        this.directRequestComponentSmall.focusFirstInput();
      }
    } else {
      let userToken = localStorage.getItem("clientToken");
      if (
        userToken !== null &&
        userToken !== "" &&
        this.user.getCurrentUser()
      ) {
        this.Common.UpdatedChecckOut.next("added");
        let status = await this.addToCart(product);
        if (status) {
          this.router.navigate(["/shop/checkout"]);
        }
      } else {
        this.goToLogin();
      }
    }

    this.buyNowLoading = false;
  }

  handleDescriptionInnerHtml(description) {
    return this.sanitizer.bypassSecurityTrustHtml(description);
  }

  isloading: boolean = false;

  async addSimilarToCart(): Promise<void> {
    this.isloading = true;
    const checkedProducts = this.similar_product.filter(
      (product) => product.checked
    );
    let anyProductAdded = false;
    for (const product of checkedProducts) {
      const toast = await this.addProductToCart(product);
      if (toast) {
        anyProductAdded = true;
      }
    }
    if (anyProductAdded) {
      if (localStorage.getItem("front-lang") == "en") {
        this.toastrService.success("Product has been added in cart.");
      } else {
        this.toastrService.success("تم إضافة المنتج الى السلة ");
      }
    }
    this.isloading = false;
  }

  // async addSimilarProductToCart(product: any, toast: boolean = true) {
  //  let selectedStore = ""
  //   let errorselectedStore
  //   let quantatyMorThanStor;
  //       if (this.userLogedIn == true) {
  //         errorselectedStore = false;
  //         quantatyMorThanStor = false;
  //         let productStors = product["stores"].filter(
  //                   (ele) => ele.store_amounts_product > 0
  //                 );
  //                 if (productStors.length == 1) {
  //                   product["product_type_id"] =
  //                     productStors[0]["product_type_id"];
  //                   selectedStore =
  //                     productStors[0]["product_type_id"];
  //                 }
  //         if (selectedStore == "") {
  //           console.log("Product Add To Cart => ", product);
  //           errorselectedStore = true;
  //         } else {
  //           product["selected_store"] = true;
  //           product["product_type_id"] = selectedStore;
  //           product.quantity = this.counter > 1 ? this.counter : 1;
  //           console.log("Product Quantity " + product.quantity);
  //           for (let i = 0; i < productStors.length; i++) {
  //             if (productStors[i].product_type_id == selectedStore) {
  //               if (
  //                 product.quantity >
  //                 productStors[i]["store_amounts_product"]
  //               ) {
  //                 quantatyMorThanStor = true;
  //                 return;
  //               }
  //             }
  //           }
  //           product["stores"] = productStors;
  //           await this.productService.addToCart(product, toast);
  //           return true;
  //         }
  //       } else {
  //         this.toastrService.error("يرجى تسجيل الدخول");
  //         this.userNotLogedInError = true;
  //         return false;
  //       }

  // }

  addProductDiscountToCart(product) {
    this.product.quantity_in_cart++;
    console.log("discount product", product);
    this.addProductToCart(product)
      .then((toast) => {
        if (toast) {
          if (localStorage.getItem("front-lang") == "en") {
            this.toastrService.success("Product has been added in cart.");
          } else {
            this.toastrService.success("تم إضافة المنتج الى السلة ");
          }
        }
      })
      .catch((error) => {
        console.error("Error adding product to cart:", error);
      });
  }

  async addProductToCart(product: any) {
    let selectedStore = "";
    let errorselectedStore;
    let quantatyMorThanStor;
    if (this.userLogedIn == true) {
      errorselectedStore = false;
      quantatyMorThanStor = false;
      let productStors = product["stores"].filter(
        (ele) => ele.store_amounts_product > 0
      );
      if (productStors.length == 1) {
        product["product_type_id"] = productStors[0]["product_type_id"];
        selectedStore = productStors[0]["product_type_id"];
      }
      if (
        selectedStore == "" ||
        product.quantity > product["stores"][0]["store_amounts_product"]
      ) {
        console.log("Product Add To Cart => ", product);
        if (localStorage.getItem("front-lang") == "en") {
          this.toastrService.error("product not available");
        } else {
          this.toastrService.error("المنتج غير متوفر ");
        }
        errorselectedStore = true;
      } else {
        product["selected_store"] = true;
        product["product_type_id"] = selectedStore;
        console.log("Product Quantity " + product.quantity);
        for (let i = 0; i < productStors.length; i++) {
          if (productStors[i].product_type_id == selectedStore) {
            if (product.quantity > productStors[i]["store_amounts_product"]) {
              quantatyMorThanStor = true;
              return;
            }
          }
        }
        product["stores"] = productStors;

        await this.productService.addToCart(product);
        // if (toast) {
        //   if (localStorage.getItem("front-lang") == "en") {
        //     this.toastrService.success("Product has been added in cart.");
        //   } else {
        //     this.toastrService.success("تم إضافة المنتج الى السلة ");
        //   }
        // }
        return true;
      }
    } else {
      this.toastrService.error("يرجى تسجيل الدخول");
      this.userNotLogedInError = true;
      return false;
    }
  }

  // Add to Wishlist
  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
    this.isFavourit = true;
  }

  // Toggle Mobile Sidebar
  toggleMobileSidebar() {
    this.mobileSidebar = !this.mobileSidebar;
  }

  getNoteDetails(noteID: number): void {
    this.noteLoader = true;
    this.Common.noteOn = true;
    this.note_id = noteID;
    document.querySelector("html")?.classList.add("no-scroll");
    this.modalRef = this.modalService.open(this.templateModalDate, {
      size: "lg",
      ariaLabelledBy: "templateModalDate-Modal",
      centered: true,
      windowClass:
        "theme-modal newsletterm NewsLetterModal modal-support no-scroll",
      scrollable: true,
      backdrop: true,
      keyboard: true,
    });

    this.modalRef.result.finally(() => {
      document.querySelector("html")?.classList.remove("no-scroll");
    });

    this.Common.getNoteDetails(noteID).subscribe((res) => {
      setTimeout(() => {
        $("#note_description").html(res.data.description);
      }, 500);
      setTimeout(() => {
        this.noteLoader = false;
      }, 500);
    });
  }

  closeTemplateSheetMenu() {
    this.Common.noteOn = false;
    this.bottomSheet.dismiss();
  }
  ngOnDestroy(): void {
    this.removeHomeMetaTags();
    this.Common.removeLinkTags(this.renderer);
    clearInterval(this.intervalId);
    this.breadCrumbSchema = null;
    this.schema = null;
    this.renderer.removeStyle(document.body, "line-height");
  }

  onCarouselTranslate(event: any) {
    const currentPosition = event["page"]["index"];
    const totalItems = event["page"]["count"];
    if (currentPosition === 0) {
      document.querySelector(".owl-prev").classList.add("hide-arrow");
    } else {
      document.querySelector(".owl-prev").classList.remove("hide-arrow");
    }
    if (currentPosition === totalItems - 1) {
      document.querySelector(".owl-next").classList.add("hide-arrow");
    } else {
      document.querySelector(".owl-next").classList.remove("hide-arrow");
    }
  }

  similarProductsSliderConfig: SwiperOptions = {
    slidesPerView: "auto",
    spaceBetween: 0,
    navigation: true,
    scrollbar: { draggable: true },
  };

  toggleProductChecked(index: number) {
    this.similar_product[index].checked = !this.similar_product[index].checked;
    this.calculateTotalPrice();
  }

  calculateTotalPrice() {
    this.totalPrice = 0;
    this.selectedProductsCount = 0;
    this.similar_product.forEach((product) => {
      if (product.checked) {
        this.totalPrice += Number(product.price);
        this.selectedProductsCount++;
      }
    });
    console.log(this.totalPrice);
  }
  calculateTextColor(themeVariable: string): string {
    const cssValue = getComputedStyle(
      document.querySelector("app-root")
    ).getPropertyValue(themeVariable);
    return this.Common.tinyColor(cssValue);
  }

  showImageModal(i: number) {
    this.modalService
      .open(this.imagesModal, {
        size: "lg",
        ariaLabelledBy: "imagesModal-Modal",
        centered: true,
        windowClass: "theme-modal newsletterm NewsLetterModal images-modal",
        scrollable: true,
        backdrop: true,
        keyboard: true,
      })
      .result.then(
        (result) => {
          $(document.body).css({
            overflow: " visible !important",
            height: "auto",
          })`Result ${result}`;
        },
        (reason) => {
          $(document.body).css({
            overflow: " visible !important",
            height: "auto",
          });
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    setTimeout(() => {
      $(document.body).css({
        overflow: " hidden !important",
        height: "100vh",
      });
      $(".mainProductSlider2")[0].swiper.slideTo(i, 0);
      $(".modal-silder").css({
        visibility: "visible",
      });
      this.selectedSildeIndex = i;
    }, 500);
  }

  hideModalSlider() {
    $(".modal-silder").css({
      visibility: "hidden",
    });
  }
  selectedSildeIndex = 0;
  changeImage(i, image) {
    console.log(i);
    this.selectedSildeIndex = i;
    this.imageZoomed = false;
    $(".mainProductSlider2")[0].swiper.slideTo(i, 0);
  }

  showDetailsImage(i, event) {
    if (this.imageZoomed) {
      const target = $(event.currentTarget);
      const rect = target[0].getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      const xPercent = (x / rect.width) * 100;
      const yPercent = (y / rect.height) * 100;
      $(target).css({
        "transform-origin": `${xPercent}% ${yPercent}%`,
        transition: "all 0.1s ease", // Optional transition
      });
    }
  }
  imageZoomed = false;
  zoomImage(event: MouseEvent, i) {
    if (this.imageZoomed) {
      this.resetZoom(event.currentTarget);
      return;
    }
    this.imageZoomed = true;
    const target = $(event.currentTarget);
    if (target.length === 0) {
      console.warn("No zoomable image found");
      return;
    }
    const rect = target[0].getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const xPercent = (x / rect.width) * 100;
    const yPercent = (y / rect.height) * 100;
    target.css({
      "transform-origin": `${xPercent}% ${yPercent}%`,
      transform: "scale(2)",
      transition: "all 0.3s ease",
    });
  }
  resetZoom(ele) {
    this.imageZoomed = false;
    $(ele).css({
      transform: "scale(1)",
      "transform-origin": "center",
      transition: "all 0.3s ease",
    });
  }
  onMouseScroll(event: WheelEvent) {
    const delta = event.deltaY;
    if (delta > 0) {
      this.performScrollDownAction(event);
    } else {
      this.performScrollUpAction(event);
    }
    event.preventDefault();
  }

  performScrollDownAction(event) {
    this.imageZoomed = true;
    const target = $(event.currentTarget);

    if (target.length === 0) {
      console.warn("No zoomable image found");
      return; // Exit if no images available
    }

    const rect = target[0].getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const xPercent = (x / rect.width) * 100;
    const yPercent = (y / rect.height) * 100;

    // Apply the zoom effect using jQuery
    target.css({
      "transform-origin": `${xPercent}% ${yPercent}%`,
      transform: "scale(2)",
      transition: "all 0.3s ease", // Optional transition
    });
  }

  performScrollUpAction(event) {
    this.imageZoomed = true;
    const target = $(event.currentTarget);

    if (target.length === 0) {
      console.warn("No zoomable image found");
      return; // Exit if no images available
    }

    const rect = target[0].getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const xPercent = (x / rect.width) * 100;
    const yPercent = (y / rect.height) * 100;

    // Apply the zoom effect using jQuery
    target.css({
      "transform-origin": `${xPercent}% ${yPercent}%`,
      transform: "scale(1)",
      transition: "all 0.3s ease", // Optional transition
    });
  }
  onSlideChange() {
    this.imageZoomed = false;
    let currentIndex = $(".mainProductSlider2")[0]?.swiper.activeIndex;
    this.selectedSildeIndex = currentIndex;
  }

  changeCursor(cursorStyle: string) {
    document.body.style.cursor = cursorStyle;
  }

  //!******************************* */
  copyToCliboard(product: Product) {
    let url = location.origin + this.router.url;
    this.clipboard.copy(decodeURIComponent(url));
    if (this.frontLang == "en") {
      this.toastrService.success("Copied to clipboard successfully");
    } else {
      this.toastrService.success("تم نسخ الرابط بنجاح");
    }
  }
  shareWithWhatsapp() {
    let url = location.origin + this.router.url;
    const message = `${url}`;
    const whatsappUrl = `https://wa.me/?text=${message}`;
    window.open(whatsappUrl, "_blank");
  }

  shareViaFacebook() {
    let url = location.origin + this.router.url;
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    window.open(facebookUrl, "_blank", "width=600,height=400");
  }

  shareViaX() {
    let url = location.origin + this.router.url;
    const tweetUrl = `https://twitter.com/intent/tweet?url=${url}`;
    window.open(tweetUrl, "_blank", "width=600,height=400");
  }
  //!******************************* */
  isMobileLogin: boolean = true;
  isEmailLogin: boolean = false;

  toggleLoginType(isEmail: boolean) {
    this.isMobileLogin = isEmail;
    this.isEmailLogin = !isEmail;
  }

  step5Reached: boolean = false;
  stepSuccessReached: boolean = false;
  timeoutId: any;

  @ViewChild("login") loginSection;
  @ViewChild("loginFast") loginFast;

  goToLogin() {
    this.loginclicked = true;
  }

  close() {
    this.loginclicked = false;
    this.step5Reached = false;
    this.clearTimeout();
    if (this.stepSuccessReached) {
      window.location.reload();
    }
  }

  onStep5Reached() {
    this.step5Reached = true;
    this.startTimeout();
  }
  onstepSuccessReached() {
    this.stepSuccessReached = true;
  }

  startTimeout() {
    this.timeoutId = setTimeout(() => {
      this.close();
    }, 500);
  }

  clearTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }
  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    if (!this.loginFast || !this.loginSection) {
      return;
    }
    const clickedInside = this.loginFast.nativeElement.contains(event.target);
    const clickedButton = this.loginSection.nativeElement.contains(
      event.target
    );

    if (clickedInside && this.loginclicked) {
      if (this.step5Reached) {
        this.close();
      }
    } else if (!clickedInside && clickedButton && this.loginclicked) {
      this.close();
    }
  }

  // *start address block************ */
  countries: any[] = [];
  country_name: any;
  delivery_price: any;
  country_id: string;
  public cities: any[] = [];
  public Districts: any[] = [];
  city_id: any = null;
  district_id: any = "";
  currentMonth: number;
  currentDay: number;
  futureDate: Date;
  months = {
    1: { en: "January", ar: "يناير" },
    2: { en: "February", ar: "فبراير" },
    3: { en: "March", ar: "مارس" },
    4: { en: "April", ar: "أبريل" },
    5: { en: "May", ar: "مايو" },
    6: { en: "June", ar: "يونيو" },
    7: { en: "July", ar: "يوليو" },
    8: { en: "August", ar: "أغسطس" },
    9: { en: "September", ar: "سبتمبر" },
    10: { en: "October", ar: "أكتوبر" },
    11: { en: "November", ar: "نوفمبر" },
    12: { en: "December", ar: "ديسمبر" },
  };
  targetDate: any[] = []; // Change this to your target date
  countdown: any[] = [];
  private intervalId: any;
  shippmentDetails: {
    delivery_price?: string;
  };
  ExpectedDate: { start?: Date; end?: Date }[] = [];
  currentCity: any;
  panelOpenState = false;
  expandedIndex: number = 0;
  timerOff: boolean[] = [];

  onChangeCountry(value) {
    if (value != "") {
      this.Common.getPlaces(
        value,
        this.city_id == null ? "" : this.city_id
      ).subscribe((data) => {
        if (data.data) {
          this.cities = data.data;
          this.CitiesFiltered = data.data;
          this.city_id = localStorage.getItem("city_id") || null;
          if (this.city_id) {
            this.onChangeCity(this.city_id);
          }
          this.cdr.detectChanges();
        } else {
          console.log("no data in countries");
        }
      });
    }
  }

  setOpenPanel(index: number, shipment_id: string): void {
    this.expandedIndex = index;
    console.log(shipment_id);
    this.shipment_id = shipment_id;
    localStorage.setItem("shipment_id", shipment_id);
    this.Common.directRequest$.next({
      city_id: this.city_id,
      shipment_id: shipment_id,
    });
  }

  onChangeCity(city_id) {
    console.log(this.expandedIndex);
    this.ExpectedDate = [];
    this.targetDate = [];
    this.countdown = [];
    this.timerOff = [];
    if (city_id != "") {
      localStorage.setItem("city_id", city_id);
      this.city_id = city_id;
      this.currentCity = this.cities.filter((ele) => ele.id == city_id)[0];
      console.log("currentCity", this.currentCity);
      this.shipment_id = localStorage.getItem("shipment_id") || "";
      const hasMatchingShipment = this.currentCity?.shipping_methods?.some(
        (ele) => ele.shipment_id == this.shipment_id
      );

      if (
        !hasMatchingShipment &&
        this.currentCity.shipping_methods &&
        this.currentCity.shipping_methods.length > 0
      ) {
        this.shipment_id = this.currentCity.shipping_methods[0].shipment_id;
      }
      console.log("hasMatch", hasMatchingShipment, this.shipment_id);

      console.log(this.currentCity);
      if (this.currentCity?.shipping_methods?.length > 0) {
        this.currentCity.shipping_methods.forEach((ele, index) => {
          this.ExpectedDate.push(this.calculateDeliveryDates(ele, index));
          this.setTargetDate(ele.hour_shipped_orders, index);
          if (!this.timerOff[index]) {
            this.startCountdown(index);
          }
        });
        console.log(this.ExpectedDate);
        console.log(this.targetDate);
        console.log(this.countdown);
      }
      this.Common.directRequest$.next({
        city_id: city_id,
        shipment_id: this.shipment_id,
      });
    } else {
      this.currentCity = "";
      this.ExpectedDate = [];
      clearInterval(this.intervalId);
    }
  }
  // Helper function to check if delivery time is invalid
  isInvalidDeliveryTime(time: any): boolean {
    return time === "0" || time === "" || time == null || time === undefined;
  }

  calculateDeliveryDates(method: any, index: number): { start: any; end: any } {
    const now = new Date();
    const addHours = (date: Date, hours: number): Date => {
      return new Date(date.getTime() + hours * 60 * 60 * 1000);
    };
    const hourShippedOrders = method.hour_shipped_orders % 24;
    const baseDate =
      now.getHours() < hourShippedOrders
        ? now
        : new Date(now.setDate(now.getDate() + 1));
    console.log("time", now.getHours());
    console.log("hourShippedOrders", method.hour_shipped_orders);

    const deliveryStartDate = +method.delivery_time_from
      ? addHours(baseDate, method.delivery_time_from)
      : 0;
    const deliveryEndDate = +method.delivery_time_to
      ? addHours(baseDate, method.delivery_time_to)
      : 0;
    return { start: deliveryStartDate, end: deliveryEndDate };
  }
  startCountdown(i: number) {
    this.intervalId = setInterval(() => {
      const now = new Date().getTime();
      const distance = this.targetDate[i]?.getTime() - now;

      if (distance < 0) {
        // this.countdown = "Countdown finished!";
        clearInterval(this.intervalId);
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.countdown[i] = {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
      // console.log(this.countdown);
    }, 1000);
  }
  setTargetDate(hoursTodeliver: any, i) {
    const now = new Date();
    console.log("setTarget", hoursTodeliver, now.getHours());
    this.timerOff[i] = hoursTodeliver <= now.getHours() ? true : false;
    console.log(this.timerOff);

    const currentHour = now.getHours();
    const currentDate = now.getDate();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    this.targetDate[i] = new Date(
      currentYear,
      currentMonth,
      currentDate,
      parseInt(hoursTodeliver),
      0,
      0
    );
    console.log(currentHour >= hoursTodeliver);

    if (currentHour >= hoursTodeliver) {
      this.targetDate[i].setDate(currentDate + 1);
    }
  }
  openBottomSheet() {
    this.CitiesFiltered = this.cities;
    this.Common.noteOn = true;
    const bottomSheetRef = this.bottomSheet.open(this.TemplateBottomSheet, {
      // hasBackdrop: true,
      hasBackdrop: true,
    });

    bottomSheetRef.afterDismissed().subscribe(() => {
      this.Common.noteOn = false;
    });
  }
  //End address block/
  onClearCity() {
    this.city_id = null;
    localStorage.removeItem("city_id");
  }
  filterCities(searchTerm): void {
    console.log("search", searchTerm);
    if (searchTerm) {
      this.CitiesFiltered = this.cities.filter((city) =>
        city.pla_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      this.CitiesFiltered = this.cities;
    }
  }

  onDirectRequestAdded() {
    this.showDirectRequestComponent = false;
  }

  shippingModal(description: string): void {
    document.querySelector("html")?.classList.add("no-scroll");

    this.modalRef=this.modalService.open(this.templateModalShipping, {
      size: "lg",
      ariaLabelledBy: "template-Shipping-Modal",
      windowClass: "shipping-modal modal-support no-scroll",
      centered: true,
      scrollable: true,
      keyboard: true,
    });
    this.modalRef.result.finally(() => {
      document.querySelector("html")?.classList.remove("no-scroll");
    });
    
    $("#shipping_description").html(description);
  }
}
