<div class="breadcrumb-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a [routerLink]="'/'">{{ "home" | translate }}</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ "coupones" | translate }}
            </li>
            <li
              class="breadcrumb-item active"
              aria-current="page"
              *ngIf="couponDetails?.title"
            >
              {{ couponDetails?.title }}
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>

<div class="page-title">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h2>{{ couponDetails?.title }}</h2>
      </div>
    </div>
  </div>
</div>
<!-- section start -->
<section class="section-b-space">
  <div class="collection-wrapper">
    <div class="container">
      <div class="d-flex flex-column align-items-center">
        <app-countdown-timer
          class="countdown-main"
          *ngIf="
            couponDetails?.coupon_end_date &&
            couponDetails?.show_end_date == '1'
          "
          [offerEndDate]="couponDetails?.coupon_end_date"
        ></app-countdown-timer>
        <div class="row main-banner-layout" *ngIf="couponDetails?.image">
          <img
            class="banner-image"
            [src]="CouponesService.offersImagesUrl + couponDetails?.image"
            [alt]="couponDetails?.title"
          />
        </div>
      </div>
      <div class="row">
        <div class="collection-content col">
          <div class="page-main-content">
            <div class="row">
              <div class="col-sm-12">
                <div class="collection-product-wrapper">
                  <div class="product-wrapper-grid">
                    <div class="row">
                      <div
                        class="col-6 col-xl-2 col-sm-6"
                        *ngFor="let product of all_products"
                      >
                        <!-- <div class="product-box"> -->
                        <app-product-box-one
                          [product]="product"
                          [currency]="productService?.Currency"
                          [thumbnail]="false"
                          [cartModal]="true"
                        >
                        </app-product-box-one>
                        <!-- </div> -->
                      </div>
                      <div
                        class="col-sm-12 text-center section-b-space mt-5 no-found"
                        *ngIf="
                          finished && !allItems.length && !all_products.length
                        "
                      >
                        <img
                          src="assets/images/empty-search1.png"
                          class="img-fluid mb-4"
                        />
                        <h3>
                          {{ "Sorry Couldn find the product" | translate }}
                        </h3>
                        <p></p>
                        <a [routerLink]="['/']" class="btn btn-solid">{{
                          "continue shopping" | translate
                        }}</a>
                      </div>
                    </div>
                  </div>
                  <!-- Infinite Scroll -->
                  <div
                    infiniteScroll
                    [infiniteScrollDistance]="3"
                    [infiniteScrollThrottle]="300"
                    (scrolled)="onScroll()"
                    *ngIf="allItems.length"
                  ></div>
                  <div class="product-infinitescroll">
                    <div class="theme-paggination-block">
                      <div class="container-fluid p-0">
                        <div class="row">
                          <div class="text-center col-sm-12">
                            <div
                              class="infinite-scrolling mt-3 mb-3 d-inline-block"
                            >
                              <img
                                src="assets/images/loader.gif"
                                *ngIf="!finished"
                              />
                              <div
                                class="loading-more active"
                                *ngIf="finished && allItems.length"
                              >
                                {{ "No More Products" | translate }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
