<div class="countdown-banner-layout">
  <div class="deal-text-block">
    <i class="fa fa-bolt icon"></i>
    <span class="text">
      {{ title }}
    </span>
  </div>
  <div class="timer-block">
    <span class="timer-text d-block">
      {{ "ends in" | translate }}
    </span>
    <div
      class="d-flex align-items-center"
      [class.rtl]="common.isRtl"
      [class.ltr]="!common.isRtl"
      style="gap: 15px"
    >
      <div class="countdown-box">
        <span>{{ remainingTime.days | number : "2.0" }}</span>
        <small>{{ "time.day" | translate }}</small>
      </div>
      <div class="countdown-box">
        <span>{{ remainingTime.hours | number : "2.0" }}</span>
        <small>{{ "time.hours" | translate }}</small>
      </div>
      <div class="countdown-box">
        <span>{{ remainingTime.minutes | number : "2.0" }}</span>
        <small>{{ "time.minutes" | translate }}</small>
      </div>
      <div class="countdown-box">
        <span>{{ remainingTime.seconds | number : "2.0" }}</span>
        <small>{{ "time.seconds" | translate }}</small>
      </div>
    </div>
  </div>
</div>
