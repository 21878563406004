import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ProductService } from 'src/app/shared/services/product.service';

@Component({
  selector: 'app-custom-redirect',
  templateUrl: './custom-redirect.component.html',
  styleUrls: ['./custom-redirect.component.scss']
})
export class CustomRedirectComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router , private productService : ProductService) {}
  product:any
  ngOnInit(): void {
    this.route.params.subscribe((params: ParamMap) => {
      const product_id = params["slug"];
      this.productService.getProductTitle(product_id).subscribe((data) => {
        this.product = data["data"];
        let titleUrl = !this.product.meta_url  ? this.removeBrackets(this.product.name).split(' ').join('-') :  this.product.meta_url.split(' ').join('-');
          this.router.navigate(['shop/product' , product_id ,titleUrl ])
        })
      })
        
  }
  removeBrackets(str: string): string {
    return str.replace(/[/(\)]/g, '');
  }
}
