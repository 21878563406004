<div class="all" [style.display]="isBrowser ? 'none' : 'flex'">
  <img src="assets/images/loader.gif" />
</div>

<div class="maintenance" *ngIf="maintenance == true">
  <img src="assets/images/maintenance.png" class="" alt="" />
  <div class="maintenance-message">
    <span>
      {{ maintenanceMessage }}
    </span>
  </div>
</div>
<div class="no-maintenance" *ngIf="maintenance == false">
  <!-- <div> -->

  <!-- <app-notification-modal *ngIf="firefox"></app-notification-modal> -->
  <ngx-loading-bar></ngx-loading-bar>
  <app-header-one></app-header-one>
  <router-outlet></router-outlet>
  <app-footer-one></app-footer-one>
  <app-customer-services-list></app-customer-services-list>
  <app-tap-to-top></app-tap-to-top>
</div>

<div
  class="layer position-absolute w-100 h-100"
  style="z-index: 123; bottom: 0; right: 0; background-color: #00000073"
  *ngIf="comserv.noteOn"
  (click)="closeTemplateSheetMenu()"
></div>
