import { CommonService } from "src/app/shared/services/common.service";
import {
  Component,
  ElementRef,
  AfterViewInit,
  ViewChild,
  Input,
} from "@angular/core";

@Component({
  selector: "app-item-slider",
  templateUrl: "./item-slider.component.html",
  styleUrls: ["./item-slider.component.scss"],
})
export class ItemSliderComponent implements AfterViewInit {
  @ViewChild("sliderContent", { static: true }) sliderContent!: ElementRef;
  @Input() items = [];
  @Input() itemStyle: { [key: string]: string } = {
    "font-size": "12px",
    "font-weight": "400",
  };
  currentIndex = 0;
  @Input() itemHeight = 24;
  animationSpeed = 500;
  delayBetweenSlides = 2000;

  tag: any;

  constructor(public CommonService: CommonService) {}

  ngAfterViewInit(): void {
    if (this.items.length <= 1) {
      return;
    }

    // setTimeout(() => {
    //   const slider = this.sliderContent.nativeElement;
    //   const firstClone = slider.firstElementChild?.cloneNode(true);

    //   if (firstClone) {
    //     slider.appendChild(firstClone);
    //   }
    // });

    setInterval(() => this.updateSlider(), this.delayBetweenSlides);
  }

  updateSlider(): void {
    const slider = this.sliderContent.nativeElement;
    this.currentIndex++;

    slider.style.transition = `transform ${this.animationSpeed}ms ease-in-out`;
    slider.style.transform = `translateY(-${
      this.currentIndex * this.itemHeight
    }px)`;

    if (this.currentIndex === this.items.length) {
      setTimeout(() => {
        slider.style.transition = "none";
        slider.style.transform = "translateY(0px)";
        this.currentIndex = 0;
      }, 0);
    }
  }

  calculateTextColor(themeVariable): string {
    return this.CommonService.tinyColor(themeVariable);
  }
}
