import { Component } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

declare var $;

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
  // add NgbModalConfig and NgbModal to the component providers
  providers: [NgbModalConfig, NgbModal],
})
export class NotificationModalComponent {

  constructor(config: NgbModalConfig, private modalService: NgbModal) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
  }

  requestPermission() {
  //   this.modalService.dismissAll();
  //   window.Notification.requestPermission().then((permission) => {
  //     console.log(permission);
  //     window.location.reload();
  //   }).catch((err) => {
  //     console.log(err);
  //   })
  }

  open(content) {
    this.modalService.open(content);
  }

  ngAfterViewInit(): void {
    window.Notification.permission === 'default' ? $(`#modal`).click() : '';
  }

}
