<div class="product-box">
  <div class="product-box-inner" *ngIf="!loader">
    <a
      [routerLink]="[
        '/shop/product/',
        product.id,
        product.meta_url
          ? product.meta_url?.split(' ').join('-').replace('+', '')
          : removeBrackets(product.name)?.split(' ').join('-').replace('+', '')
      ]"
    >
      <div class="img-wrapper">
        <div class="lable-block">
          <span
            #label
            class="lable-outofstock"
            *ngIf="
              (product.store_amounts == 0 && product.price == 0) ||
              (product.store_amounts == 0 && product.price > 0)
            "
            [class]="Common.isRtl ? 'label-rtl' : 'label-ltr'"
          >
            {{ "out of stock" | translate }}
          </span>
          <span #label class="lable3" *ngIf="product.new">new</span>
          <span #label class="lable4" *ngIf="product.sale">on sale</span>
          <span
            #label
            class="lable5"
            *ngIf="
              product.attachment_id > 0 &&
              product.attachment_name != null &&
              ((product.store_amounts !== 0 && product.price !== 0) ||
                (product.store_amounts !== 0 && product.price! > 0))
            "
            [style.background]="product['attachment_color']"
            [class]="Common.isRtl ? 'label-rtl' : 'label-ltr'"
          >
            {{ product.attachment_name }}
          </span>
          <span #label class="lable6" *ngIf="product.offer_id > 0">
            <b>{{ product.offer_data["attachment_single"] }}</b>
          </span>
        </div>

        <div class="front">
          <a>
            <div class="image-frame" *ngIf="product.images.length > 0">
              <img
                *ngIf="product.images[0]['type'] !== 'video'"
                [defaultImage]="
                  ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'
                "
                [lazyLoad]="
                  ImageSrc ? ImageSrc : urlimage + product.original_image
                "
                class="img-fluid lazy-loading"
                alt=" defaultImage"
                (error)="handleImageError($event)"
              />

              <video
                *ngIf="product.images[0]['type'] === 'video'"
                controls
                class="img-fluid lazy-loading"
                [id]="'currentVideo' + product.id"
                (play)="stopAllMedia(product.id)"
                (error)="handleVideoError($event)"
              >
                <source [src]="urlimage + product.images[0]['name']" />
              </video>
            </div>
            <div class="image-frame" *ngIf="product.images.length == 0">
              <img
                src="assets/images/product/placeholder.jpg"
                class="img-fluid lazy-loading"
                alt="defaultImage"
              />
            </div>
          </a>
        </div>

        <div class="back" *ngIf="onHowerChangeImage">
          <a>
            <img
              [src]="ImageSrc ? ImageSrc : product.images[1].src"
              class="img-fluid lazy-loading"
              alt="{{ product?.name }}"
            />
          </a>
        </div>
        <ul class="product-thumb-list" *ngIf="thumbnail">
          <li
            class="grid_thumb_img"
            [class.active]="ImageSrc === image"
            *ngFor="let image of product.images"
          >
            <span (mouseover)="ChangeVariantsImage(image)">
              <img [src]="image" [alt]="product?.name" />
            </span>
          </li>
        </ul>
      </div>
      <div class="product-detail">
        <div class="stats" #testOverFlow *ngIf="false">
          <span class="stat-2">
            <a
              [routerLink]="
                ['/shop/trademarkes/' + product?.trademark_id] +
                '/' +
                product?.trademark_title
              "
            >
              {{ product?.trademark_title }}
            </a>
          </span>

          <span class="stat-2 barcode">
            <span>
              {{ product?.product_barcode }}
            </span>
          </span>
        </div>

        <div class="p-content">
          <a class="p_name">
            <h2
            class="h2-overflow-ellipsis"
              style="text-transform: none"
              *ngIf="
                currentRoute == 'category' ||
                currentRoute == 'trademarkes' ||
                currentRoute == 'search' ||
                currentRoute == 'Category'
              "
            >
              {{ product?.name | titlecase }}
            </h2>
            <h3 class="h2-overflow-ellipsis" *ngIf="currentRoute == 'product' || !currentRoute">
              {{ product?.name | titlecase }}
            </h3>
            <h3 class="h2-overflow-ellipsis" *ngIf="currentRoute == 'formshapes'">
              {{ product?.name | titlecase }}
            </h3>
            <h3 class="h2-overflow-ellipsis" *ngIf="currentRoute == 'offer'">
              {{ product?.name | titlecase }}
            </h3>
            <h3 class="h2-overflow-ellipsis" *ngIf="currentRoute == 'suggesstion'">
              {{ product?.name | titlecase }}
            </h3>
            <h3 class="h2-overflow-ellipsis" *ngIf="currentRoute == 'key_words'">
              {{ product?.name | titlecase }}
            </h3>
          </a>
          <p
            *ngIf="product?.description"
            [innerHtml]="product?.description | truncate : 150"
          ></p>

          <div
            class="box-container"
            [ngClass]="{
              'has-content':
                +product.count_products_related > 1 ||
                +product.count_quantity_discount > 1
            }"
          >
            <h3 class="option-text" *ngIf="+product.count_products_related > 1">
              {{ "Options available inside" | translate }}
            </h3>
          </div>
          <app-item-slider itemHeight="17" [items]="product?.tags"></app-item-slider>

          <!-- <div class="text-right mt-1" (click)="openModelSimilarProduct($event)">
            <span class="text-red underline-decoration">
              شاهد منتجات متشابهة
            </span>
          </div> -->

          <div
            class="d-flex align-items-center price"
            *ngIf="
              showPrice &&
              !(
                (product.store_amounts == 0 && product.price == 0) ||
                (product.store_amounts == 0 && product.price > 0)
              )
            "
          >
            <span
              *ngIf="
                product?.price > 0 &&
                (product?.last_price == null || product?.last_price == '')
              "
            >
              <span>
                {{ product?.price | discount : product }}
              </span>
              <span>
                {{ currency?.currency }}
              </span>
            </span>

            <div
              class="discount-price"
              *ngIf="product?.price > 0 && product?.last_price > 0"
            >
              <div class="new-price">
                <span>{{ product?.price }}</span>
                <span>
                  {{ currency?.currency }}
                </span>
              </div>

              <div class="old-price">
                <del class="money">
                  {{ product?.last_price }}
                  {{ currency?.currency }}
                </del>
              </div>
            </div>
          </div>
          <div
            class="image-shipping"
            [ngStyle]="{
              'margin-top':
                ((product.store_amounts == 0 && product.price == 0) ||
                  (product.store_amounts == 0 && product.price > 0)) &&
                product.product_group_data &&
                product.product_group_data.img
                  ? '25px'
                  : '0px'
            }"
          >
            <img
              *ngIf="
                product.product_group_data && product.product_group_data.img
              "
              [src]="
                Common._ImageUrl + 'groups/' + product.product_group_data.img
              "
              alt="Shipping image"
              width="auto"
              height="20"
              (error)="handleImageError($event)"
            />
            <div
              [ngStyle]="{
                'margin-right':
                  !product?.product_group_data?.img && Common.isRtl
                    ? 'auto'
                    : '',
                'margin-left':
                  !product?.product_group_data?.img && !Common.isRtl
                    ? 'auto'
                    : ''
              }"
              class="discount-block align-content-center"
              *ngIf="
                product?.last_price &&
                product?.price &&
                showPrice &&
                !(
                  (product.store_amounts == 0 && product.price == 0) ||
                  (product.store_amounts == 0 && product.price > 0)
                )
              "
            >
              <span dir="ltr" style="padding: 2px">
                {{
                  getDiscountPercentage(product?.last_price, product?.price) *
                    -1
                }}
                <span>%</span>
              </span>
            </div>
          </div>

          <div
            class="cart-info cart-wrap d-flex align-items-center"
            *ngIf="product.active == 2"
            [ngStyle]="{
              'margin-top':
                product.store_amounts == 0 &&
                product.price > 0 &&
                (!product.product_group_data || !product.product_group_data.img)
                  ? '25px'
                  : '0'
            }"
          >
            <button
              type="button"
              (click)="getNoteDetails(2)"
              class="btn btn-soon1"
            >
              {{ "Inventory is in progress" | translate }}
            </button>

            <div class="wishlist-button">
              <button title="Add to Wishlist" (click)="addToWishlist(product)">
                <i
                  class="fa fa-heart"
                  [ngClass]="isFavourit ? 'text-danger' : 'text-light'"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </a>
    <div
      class="cart-info cart-wrap d-flex align-items-center"
      *ngIf="product.active != 2"
      [ngStyle]="{
        'margin-top':
          ((product.store_amounts == 0 && product.price == 0) ||
            (product.store_amounts == 0 && product.price > 0)) &&
          (!product.product_group_data || !product.product_group_data.img)
            ? '25px'
            : '0px'
      }"
    >
      <button
        class="btn btn-solid dynamic-text-color-1"
        *ngIf="
          product.store_amounts > 0 &&
          product.price > 0 &&
          product?.stores?.length == 1 &&
          +product.quantity_in_cart == 0
        "
        (click)="!product.isAddToCart && addToCart(product)"
      >
        {{ "add-to-cart" | translate }}
      </button>

      <button
        class="btn btn-product-added"
        disabled
        *ngIf="+product.quantity_in_cart > 0 && !(product.store_amounts == 0)"
      >
        {{ "product-added" | translate }}
      </button>

      <button
        href="javascript:void(0)"
        class="btn btn-soon1"
        *ngIf="
          (product.store_amounts == 0 && product.price == 0) ||
          (product.store_amounts == 0 && product.price > 0)
        "
        (click)="openNotifyMeModal(notifyModal, product.id)"
      >
        {{ "Notify me" | translate }}
      </button>

      <a
        class="btn btn-solid dynamic-text-color-1 w-100"
        style="white-space: nowrap; padding: 4px"
        [routerLink]="[
          '/shop/product/',
          product.id,
          !product.meta_url
            ? removeBrackets(product.name)
                ?.split(' ')
                .join('-')
                .replace('+', '')
            : product.meta_url?.split(' ').join('-').replace('+', '')
        ]"
        *ngIf="
          product.store_amounts > 0 &&
          product.price > 0 &&
          product?.stores?.length > 1
        "
      >
        {{ "Select options" | translate }}
      </a>

      <button
        (click)="getNoteDetails(1)"
        class="btn btn-soon"
        style="cursor: default"
        *ngIf="product.store_amounts > 0 && product.price == 0"
      >
        {{ "Soon" | translate }}
      </button>

      <!-- <button mat-button id="simple" [matBadge]="simpleContent">Simple</button> -->

      <div class="wishlist-button">
        <button title="Add to Wishlist" (click)="addToWishlist(product)">
          <i
            class="fa fa-heart"
            [ngClass]="isFavourit ? 'text-danger' : 'text-light'"
            aria-hidden="true"
          ></i>
        </button>
      </div>
    </div>
  </div>
  <app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
  <app-quick-view
    #quickView
    [product]="product"
    [currency]="currency"
  ></app-quick-view>
  <app-cart-modal
    #cartModal
    [product]="product"
    [currency]="currency"
    *ngIf="cartModal"
  ></app-cart-modal>
</div>

<ng-template #notifyModal let-modal>
  <app-notify-me-modal
    [productIdToListenToAvailability]="product.id"
  ></app-notify-me-modal>
</ng-template>

<!-- Notes Prodcut Status -->
<ng-template
  class="notesSheet"
  let-bottomSheetRef="bottomSheetRef"
  #templateBottomSheet
>
  <!-- <div class="top-0 left-0 layer-box w-100 h-100 bg-danger" style="position: fixed  ;z-index: 99999 ; bottom: 100%;"></div> -->
  <div
    class="d-flex justify-content-between align-items-center note_title"
    style="border-bottom: 1px solid #eee; background: #fff; margin-inline: 10px"
  >
    <div id="note_title" style="font-size: 20px" *ngIf="note_id === 1">
      قريبا
    </div>
    <div id="note_title" style="font-size: 20px" *ngIf="note_id === 2">
      ملاحظة جاري الجرد
    </div>
    <div id="note_title" style="font-size: 20px" *ngIf="note_id === 3">
      ملاحظة تاريخ الصلاحية
    </div>

    <button
      (click)="closeTemplateSheetMenu()"
      class="bg-transparent border-0 text-dark"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- Loader -->
  <div class="pt-5 text-center loader" *ngIf="noteLoader">
    <img src="assets/images/loader.gif" />
  </div>

  <div *ngIf="!noteLoader">
    <div id="note_description" class="p-2 text-center"></div>
  </div>
</ng-template>

<!-- shipping -->
<ng-template class="theme-modal" let-modal #templateModalShipping>
  <!-- <div class="top-0 left-0 layer-box w-100 h-100 bg-danger" style="position: fixed  ;z-index: 99999 ; bottom: 100%;"></div> -->
  <div
    class="d-flex justify-content-between align-items-center note_title"
    style="border-bottom: 1px solid #eee; background: #fff; margin-inline: 10px"
  >
    <div id="note_title" style="font-size: 20px">الشحن</div>

    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div
    id="shipping_description"
    class="p-2 text-center shipping_description"
  ></div>
</ng-template>



<!-- Similar products -->
<ng-template class="theme-modal" #templateModalSimilarProduct let-modal>
  <div class="close-modal-btn">
    <div class="p-1 m-auto modal-bg d-block">
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="privacy-modal overflow-auto modal-privacy">
    <div class="modal-header">
      <div
        class="text-center w-100"
        *ngIf="product.product_group_data && product.product_group_data.img"
      >
        <img
          [src]="Common._ImageUrl + 'groups/' + product.product_group_data.img"
          alt="Shipping image"
          width="auto"
          height="25"
          (error)="handleImageError($event)"
        />
      </div>
    </div>
    <div class="modal-body modal7" style="background-image: unset">
      <div class="p-0 container-fluid">
        <div
          id="shipping_description"
          class="p-2 text-center shipping_description"
        ></div>
      </div>
    </div>
  </div>
</ng-template>