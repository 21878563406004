import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { interval, Subscription } from "rxjs";
import { takeWhile, map } from "rxjs/operators";
import { CommonService } from "src/app/shared/services/common.service";

@Component({
  selector: "app-countdown-timer",
  templateUrl: "./countdown-timer.component.html",
  styleUrls: ["./countdown-timer.component.scss"],
})
export class CountdownTimerComponent implements OnInit, OnDestroy {
  
  @Input() offerEndDate: any;
  @Input() title: any;
  remainingTime: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  } = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  private subscription: Subscription | undefined;

  constructor(public common:CommonService){

  }
  ngOnInit() {
    if (this.offerEndDate) {
      const endDate = new Date(this.offerEndDate);
      this.startCountdown(endDate);
    }
  }

  private startCountdown(endDate: Date): void {
    this.subscription = interval(1000)
      .pipe(
        takeWhile(() => new Date().getTime() < endDate.getTime()),
        map(() => {
          const now = new Date();
          const timeDiff = endDate.getTime() - now.getTime();
          return {
            days: Math.floor(timeDiff / (1000 * 60 * 60 * 24)),
            hours: Math.floor(
              (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            ),
            minutes: Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((timeDiff % (1000 * 60)) / 1000),
          };
        })
      )
      .subscribe((time) => {
        this.remainingTime = time;
        if (
          this.remainingTime.days === 0 &&
          this.remainingTime.hours === 0 &&
          this.remainingTime.minutes === 0 &&
          this.remainingTime.seconds === 0
        ) {
          this.subscription?.unsubscribe();
        }
      });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
