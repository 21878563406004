<!-- <header class="sticky mid-header" [ngClass]="class" [class.fixed]="stick && sticky"> -->
<header class="mid-header" [ngClass]="class">
  <app-download-app-banner></app-download-app-banner>

  <!-- <div class="mobile-fix-option"></div> -->
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact">
            <ul>
              <li class="mobile-wishlist">
                <a
                  class="dynamic-text-color-2"
                  id="call-us"
                  [href]="'tel:' + mobileNumber"
                >
                  <i class="fa fa-phone dynamic-text-color-2" aria-hidden="true"></i>
                  {{ "Call Us" | translate }} &nbsp;&nbsp;
                  {{ mobileNumber }}
                </a>
              </li>
              <li class="onhover-dropdown">
                <span
                  *ngIf="frontLang === 'ar'"
                  class="d-flex justify-content-center align-items-center cursor"
                >
                  <img
                    class="mx-2 flag"
                    src="https://www.countryflags.com/wp-content/uploads/saudi-arabia-flag-png-large.png"
                    alt="arabic"
                    width="24px"
                    height="16px"
                  />
                  <span class="dynamic-text-color-2">
                    {{ "arabic" | translate }}
                  </span>
                </span>
                <span *ngIf="frontLang === 'en'" class="dynamic-text-color-2">
                  <img
                    class="flag"
                    src="https://www.countryflags.com/wp-content/uploads/united-states-of-america-flag-png-large.png"
                    alt="english"
                    width="24px"
                    height="16px"
                  />
                  {{ "english" | translate }}
                </span>
                <ul class="onhover-show-div">
                  <li>
                    <button
                      class="cursor btn-lang"
                      data-lng="ar"
                      (click)="changeLanguage('ar')"
                    >
                      <img
                        class="flag"
                        src="https://www.countryflags.com/wp-content/uploads/saudi-arabia-flag-png-large.png"
                        alt="arabic"
                      />
                      <span class="mx-2">{{ "arabic" | translate }} </span>
                    </button>
                  </li>
                  <li>
                    <button
                      class="cursor btn-lang"
                      data-lng="es"
                      (click)="changeLanguage('en')"
                    >
                      <img
                        class="flag"
                        src="https://www.countryflags.com/wp-content/uploads/united-states-of-america-flag-png-large.png"
                        alt="english"
                      />
                      <span class="mx-2">{{ "english" | translate }} </span>
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-right col-lg-6">
          <ul class="header-dropdown">
            <li class="onhover-dropdown mobile-account" *ngIf="userLogedIn">
              <div class="bell-notification-number-block">
                <a
                  [routerLink]="['/pages/notifications']"
                  aria-label="Go to notifications page"
                >
                  <i class="fa fa-bell text-light fa-icon"></i>
                </a>
                <span class="cart_qty_cls dynamic-text-color-1">{{ notifictionNum }}</span>
              </div>

              <ul class="onhover-show-div notifications-list">
                <li *ngIf="notificationsList.length">
                  <a [routerLink]="['/pages/notifications']" data-lng="en">
                    <i class="fa fa-bell"></i>
                    {{ "notifications" | translate }}
                  </a>
              
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="container header-container1">
    <div class="row top-header2">
      <div class="col-sm-12">
        <div class="main-menu" style="direction: rtl">
          <div class="menu-left">
            <app-left-menu></app-left-menu>
            <div class="brand-logo">
              <a routerLink="/" id="headerlogo">
                <img
                  [defaultImage]="'assets/images/product/placeholder.jpg'"
                  [lazyLoad]="logourl"
                  class="img-fluid"
                  alt="logo"
                />
              </a>
            </div>

            <div>
              <div class="icon-block">
                <div
                  class="search-block"
                  (click)="showSearchBar = !showSearchBar"
                >
                  <img
                    *ngIf="screenWidth <= 577"
                    src="assets/images/icon/search.png"
                    class="img-fluid"
                    alt="shopping cart icon"
                  />
                </div>

                <a
                  class="notification-bell-block"
                  aria-label="wishList"
                  [routerLink]="['/shop/wishlist']"
                >
                  <i class="fa fa-heart-o" aria-hidden="true"> </i>
                </a>

                <a
                  class="notification-bell-block"
                  aria-label="notifications"
                  *ngIf="user.getCurrentUser()"
                  [routerLink]="['/pages/notifications']"
                >
                  <i class="fa fa-bell-o" aria-hidden="true">
                    <div
                      class="notifications-counter dynamic-text-color-1"
                      *ngIf="notificationsList.length"
                    >
                      {{ notificationsList.length }}
                    </div>
                  </i>
                </a>

                <a class="cart-block" [routerLink]="['/shop/cart']">
                  <!-- DESKTOP CART ICON -->
                  <img
                    *ngIf="screenWidth > 577"
                    src="assets/images/icon/cart-white.png"
                    class="img-fluid"
                    alt="shopping cart icon"
                    width="25"
                    height="25"
                  />
                  <!-- MOBILE CART ICON -->
                  <div class="cart-icon-block" *ngIf="screenWidth <= 577">
                    <img
                      src="assets/images/icon/cart.png"
                      class="img-fluid"
                      width="25"
                      height="25"
                      alt="shopping cart icon"
                    />
                    <div class="cart-counter dynamic-text-color-1">
                      {{ products.length }}
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div>
            <form
              class="form_search"
              id="form_search1"
              (ngSubmit)="goToSearch()"
            >
              <input
                id="query search-autocomplete"
                type="text"
                autocomplete="off"
                name="query"
                #searchKeyE
                [(ngModel)]="query"
                placeholder="{{ 'Search a Product' | translate }}"
                class="nav-search nav-search-field"
                [style.direction]="
                  commonService._lang_code == 'ar' ? 'rtl' : 'ltr'
                "
                (keyup)="getProductsAutoComplete()"
              />
              <button
                type="submit"
                name="nav-submit-button"
                class="btn-search"
                aria-label="Search"
              >
                <i class="ti-search"></i>
              </button>
              <mat-icon
                style="color: red; cursor: pointer"
                class="close-search-btn"
                *ngIf="query.length > 0"
                (click)="clearSearch()"
                [ngStyle]="{
                  left: commonService.isRtl ? '50px' : 'auto',
                  right: commonService.isRtl ? 'auto' : '50px'
                }"
              >
                close
              </mat-icon>
            </form>

            <div
              class="row"
              id="search-autocomplete-server"
              *ngIf="query != ''"
            >
              <div class="col-sm-12">
                <ul
                  #dropdown
                  *ngIf="query.trim().length > 0"
                  class="list-group list-group-flush"
                  [class.product_search_height]="
                    searchAutoCompleteBrands.length != 0 ||
                    searchAutoCompleteData.length != 0 ||
                    searchAutoCompleteSuggesstions.length != 0
                  "
                >
                  <!-- <div *ngFor="let searchitem of searchAutoCompleteData">
                                        <li class="list-group-item search_item" *ngIf="searchitem.store_amounts > 0 && searchitem.price > 0">
                                            <div class="first">
                                                <a [routerLink]="['/shop/product/', searchitem.id]">
                                                    <img [src]="urlimage+searchitem.image" [alt]="searchitem?.name" class="product-image">
                                                </a>
                                            </div>
                                            <div class="second">
                                                <h4>
                                                    <a [routerLink]="['/shop/product/', searchitem.id]">
                                                    {{Commen.limitString(searchitem.name,90) }}
                                                </a>
                                                </h4>
                                                <h4 class="price-hover" *ngIf="searchitem.price">
                                                    {{searchitem.price}} {{productService?.Currency.name}}

                                                    <del *ngIf="(searchitem.price > 0) && (searchitem.last_price > 0)" class="pt-1 d-block">{{ searchitem.last_price }}
                                                    {{productService?.Currency.name}}</del>

                                                </h4>
                                            </div>
                                        </li>
                                    </div> -->
                  <!-- ----------------------------------------------------------------------- -->
                  <!--                    ALL SEARCH AUTO COMPLETE START                    -->
                  <!-- ----------------------------------------------------------------------- -->
                  <div>
                    <li
                      class="list-group-item search_item justify-content-between"
                      [class.link-en]="!commonService.isRtl"
                    >
                      <a
                        style="cursor: pointer"
                        class="suggested-item"
                        [routerLink]="
                          '/pages/search/' +
                          removeBrackets(query).split(' ').join('-')
                        "
                        innerHTML="{{ query }}"
                        (click)="closeDropdown()"
                      >
                      </a>
                      <a
                        (click)="goToSearch()"
                        class="d-flex justify-content-center align-items-center"
                        [class.link-en]="!commonService.isRtl"
                      >
                        <span style="color: #1da159; cursor: pointer">
                          {{ "watch more" | translate }}
                        </span>
                        <mat-icon style="color: #696973; cursor: pointer"
                          >call_made</mat-icon
                        >
                      </a>
                    </li>
                  </div>
                  <!-- --------------- END OF ALL SEARCH AUTO COMPLETE --------------- -->

                  <!-- ----------------------------------------------------------------------- -->
                  <!--                    BRANDS SEARCH AUTO COMPLETE START                    -->
                  <!-- ----------------------------------------------------------------------- -->
                  <div *ngIf="searchAutoCompleteBrands">
                    <div *ngFor="let brand of searchAutoCompleteBrands">
                      <li
                        class="list-group-item search_item d-flex justify-content-between"
                        [class.link-en]="!commonService.isRtl"
                      >
                        <a
                          style="cursor: pointer"
                          class="suggested-item brand-search-container"
                          [routerLink]="
                            '/shop/trademarkes/' +
                            brand.id +
                            '/' +
                            brand.title.split(' ').join('-')
                          "
                          (click)="closeDropdown()"
                        >
                          <span class="">{{ brand.title }}</span>
                          <div class="badge-brand">
                            <span>
                              {{ "brand" | translate }}
                            </span>
                          </div>
                        </a>
                        <mat-icon
                          [routerLink]="
                            '/shop/trademarkes/' +
                            brand.id +
                            '/' +
                            brand.title.split(' ').join('-')
                          "
                          (click)="closeDropdown()"
                          style="color: #696973; cursor: pointer"
                          >call_made</mat-icon
                        >
                      </li>
                    </div>
                  </div>

                  <!-- --------------- END OFBRANDS SEARCH AUTO COMPLETE --------------- -->

                  <div *ngIf="searchAutoCompleteSuggesstions">
                    <div
                      *ngFor="let suggested of searchAutoCompleteSuggesstions"
                    >
                      <li
                        class="list-group-item search_item d-flex justify-content-between align-items-center"
                      >
                        <a
                          style="cursor: pointer"
                          class="suggested-item"
                          (click)="
                            goToSearchSuggested(suggested.suggesstion);
                            closeDropdown()
                          "
                          innerHTML="{{ suggested.suggesstion }}"
                        >
                        </a>
                        <!-- <mat-icon
                                                    (click)="goToSearchSuggested(suggested.suggesstion) ; closeDropdown() "
                                                    style="color: #696973;cursor: pointer;">call_made</mat-icon> -->
                      </li>
                    </div>
                  </div>

                  <div *ngFor="let searchitem of searchAutoCompleteData">
                    <li
                      class="list-group-item search_item d-flex justify-content-between align-items-center product-item"
                      [class.link-en]="!commonService.isRtl"
                    >
                      <div class="first">
                        <a
                          style="cursor: pointer"
                          (click)="productDetails(searchitem); closeDropdown()"
                          class="product-image-container"
                        >
                          <img
                            [src]="urlimage + searchitem.original_image"
                            [alt]="searchitem?.name"
                            class="product-image"
                            (error)="handleImageError($event.target)"
                          />
                        </a>
                      </div>
                      <div class="second">
                        <h4>
                          <a
                            style="cursor: pointer"
                            (click)="
                              productDetails(searchitem); closeDropdown()
                            "
                          >
                            {{ commonService.limitString(searchitem.name, 90) }}
                          </a>
                        </h4>
                        <div
                          class="price-hover d-flex align-items-center"
                          [class.link-en]="!commonService.isRtl"
                          *ngIf="
                            (+searchitem.store_amounts !== 0 &&
                              +searchitem.price !== 0) ||
                            (+searchitem.store_amounts !== 0 &&
                              +searchitem.price! > 0)
                          "
                        >
                          <del
                            *ngIf="
                              searchitem.price > 0 && searchitem.last_price > 0
                            "
                            class="pt-1 d-block font-weight-bold"
                            style="color: black; font-size: 14px"
                            >{{ searchitem.last_price }}
                            {{ productService?.Currency.name }}</del
                          >
                          <span
                            class="mx-2 font-weight-bold align-self-end"
                            style="color: var(--theme-default)"
                          >
                            {{ searchitem.price }}
                            {{ productService?.Currency.name }}
                          </span>
                        </div>
                      </div>
                    </li>
                  </div>

                  <!-- <div class="text-center category-image" *ngIf="searchLoader || productLoader"
                                        style="background: #FFF">
                                        <img src="assets/images/slider/Spinner-1s-200px.gif" alt=""
                                            style="width: 50px;">
                                    </div> -->

                  <!-- <li class="list-group-item cursor_pointer" *ngIf="searchAutoCompleteExist"
                                        (click)="goToSearch()">
                                        {{'watch more' | translate}} <i class="fa fa-arrow-right"></i>
                                    </li> -->

                  <li
                    class="list-group-item"
                    *ngIf="
                      !searchAutoCompleteExist &&
                      searchAutoCompleteData.length == 0 &&
                      searchAutoCompleteSuggesstions.length == 0 &&
                      searchAutoCompleteBrands.length == 0
                    "
                  >
                    {{ "no data" | translate }}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="menu-right pull-right">
            <app-settings></app-settings>
          </div>
        </div>
        <!-- <div class="main-menu" *ngIf="false">
                    <div class="menu-left">
                        <app-left-menu></app-left-menu>
                        <div class="brand-logo">
                            <a routerLink="/home" id="headerlogo">
                                <img [src]="logourl" class="img-fluid" alt="logo">
                            </a>
                        </div>
                    </div>
                    <div class="menu-right pull-right">
                        <div>
                            <app-menu></app-menu>
                        </div>
                        <div>
                            <app-settings></app-settings>
                        </div>
                    </div>
                </div> -->
      </div>
    </div>

    <!-- MOBILE SEARCH BAR -->
    <div class="mobile-search-bar" *ngIf="showSearchBar">
      <form class="form_search" id="form_search2">
        <input
          id="query search-autocomplete"
          type="text"
          autocomplete="off"
          name="query"
          [(ngModel)]="query"
          placeholder="{{ 'Search a Product' | translate }}"
          class="nav-search nav-search-field"
          (keyup)="getProductsAutoComplete()"
          #inputSearch
        />
        <button
          type="submit"
          name="nav-submit-button"
          class="btn-search"
          (click)="clearSearch(inputSearch)"
          aria-label="Search"
        >
          <mat-icon
            style="color: red; cursor: pointer"
            *ngIf="query.length > 0"
          >
            close
          </mat-icon>
          <mat-icon style="color: #696973" *ngIf="query.length == 0"
            >search</mat-icon
          >
        </button>
      </form>

      <!-- <div class="row" id="search-autocomplete-server" *ngIf="searchAutoComplete">
                <div class="col-sm-12">

                    <ul class="list-group list-group-flush">
                        <div *ngFor="let suggested of searchAutoCompleteSuggesstions">
                            <li class="list-group-item search_item">
                                <a style="cursor: pointer;" class="suggested-item"
                                    (click)="goToSearchSuggested(suggested.suggesstion)"
                                    innerHTML="{{ suggested.suggesstion }}">

                                </a>
                            </li>
                        </div>
                        <div *ngFor="let searchitem of searchAutoCompleteData">
                            <li class="list-group-item search_item">
                                <div class="first">
                                    <a style="cursor: pointer;" (click)="productDetails(searchitem.id)">
                                        <img [src]="urlimage+searchitem.image" [alt]="searchitem?.name"
                                            class="product-image">
                                    </a>
                                </div>
                                <div class="second">
                                    <h4>
                                        <a style="cursor: pointer;" (click)="productDetails(searchitem.id)">
                                            {{Commen.limitString(searchitem.name,90) }}
                                        </a>
                                    </h4>
                                    <h4 class="price-hover" *ngIf="(searchitem.store_amounts !== 0 &&
                                            searchitem.price !== 0)
                                            ||(searchitem.store_amounts !== 0 &&
                                            searchitem.price!> 0)">
                                        {{searchitem.price}} {{productService?.Currency.name}}

                                        <del *ngIf="(searchitem.price > 0) && (searchitem.last_price > 0)"
                                            class="pt-1 d-block">{{ searchitem.last_price }}
                                            {{productService?.Currency.name}}</del>
                                    </h4>
                                </div>
                            </li>
                        </div>


                        <li class="list-group-item cursor_pointer" *ngIf="searchAutoCompleteExist"
                            (click)="goToSearch()">
                            {{'watch more' | translate}} <i class="fa fa-arrow-right"></i> </li>

                        <li class="list-group-item" *ngIf="!searchAutoCompleteExist">{{'no data' | translate}}</li>

                    </ul>
                </div>
            </div> -->
    </div>
  </div>

  <div class="bottom-part">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <app-menu></app-menu>
          <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis animi vel aliquid quas quis sint. Amet at eveniet quam, aspernatur placeat laborum sequi ea voluptas repellat iure iusto dolores eos?</p> -->
        </div>
      </div>
    </div>
  </div>
</header>

<!-- login -->
<section #login class="full-sidebar" *ngIf="loginclicked">
  <div class="shadow login-sidebar">
    <i class="ti-close login-icon" (click)="close()"></i>
    <app-fast-login
      (step5Reached)="onStep5Reached()"
      (stepSuccessReached)="onstepSuccessReached()"
    ></app-fast-login>
  </div>
</section>
