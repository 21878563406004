<div
  *ngIf="innerWidth < 577 && downloadAppBar && downloadLink"
  class="download-app-banner"
>
  <div class="icon-close-button-section">
    <div class="close-download-banner" (click)="closeDownloadBanner()">
      <i class="ti-close dynamic-text-color-1"></i>
    </div>
    <div class="play-ios-store-block">
      <a href="{{ contactData?.app_url_android }}" target="_blank">
        <img
          src="assets/images/download-google-play.webp"
          alt="Get Zahra EG App at Google Play Store"
        />
      </a>
      <a href="{{ contactData?.app_url_ios }}" target="_blank">
        <img
          src="assets/images/download-app-store.webp"
          alt="Get Zahra EG App at App Store"
        />
      </a>
    </div>
    <div class="logo-icon">
      <img [src]="websiteFavIcon" alt="Zahra Egypt App" />
    </div>
    <div class="description-section">
      <span class="dynamic-text-color-1">
        {{ contactData?.front_name }} App
      </span>
    </div>
  </div>
  <a href="{{ downloadLink }}" target="_blank">
    <div class="download-app-button">
      <span>
        {{ "download" | translate }}
      </span>
      <!-- <i class="ti-download"></i> -->
    </div>
  </a>
</div>
