import { NotificationModalComponent } from './../components/modal/notification-modal/notification-modal.component';
import { NotificationComponent } from './../../pages/account/notification/notification.component';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { getMessaging, getToken } from 'firebase/messaging';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  tokenID: string = '';
  public tokenUrl = this.common._hostName + "notifications.php?action=";
  constructor(
    private http: HttpClient,
    private common: CommonService,
    public notificationModalComponent: NotificationModalComponent
  ) {
  }

  sendTokenApi(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this.tokenUrl + "setDeviceToken", body);
  }


  // initialize() {
  //   return this.getDeviceId();
  // }

  // getDeviceId() {

  //     window.Notification.requestPermission(permission => {
  //       console.log(permission);
  //     })
  
  //     if (!localStorage.getItem('Device-Id') || localStorage.getItem('Device-Id') == "0") {
  //       const message = getMessaging();
  //       getToken(message, { vapidKey: environment.firebase.vapidKey }).then((currentToken => {
  //         if (currentToken) {
  //           console.log(currentToken);
  //           // Send Token To Api
  //           let data = {
  //             device_token_id: currentToken,
  //             type: "web",
  //             lang_code: localStorage.getItem("front-lang")
  //           };
  //           this.sendTokenApi(data).subscribe(success => {
  //             console.log(JSON.stringify(success));
  //             this.tokenID = success.data;
  //             localStorage.setItem("Device-Id", success.data);
  //           });
  //         }
  //         else {
  //           // alert("السماح بالاشعارات من فضلك");
  //           this.notificationModalComponent.ngAfterViewInit();
  //         }
  //       })).catch((error) => {
  //         console.log(error);
  //         // // alert("يرحى السماح بتلقى الاشعارات");
  
  //       });
  //     }
    
  // }

}
