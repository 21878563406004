import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "src/app/shared/services/common.service";
import { MasterApiService } from "src/app/shared/services/master-api.service";
import { ProductService } from "src/app/shared/services/product.service";
import { ExtractedData } from "src/assets/interfaces/category-support";
import SwiperCore, {
  FreeMode,
  Navigation,
  Pagination,
  SwiperOptions,
  Thumbs,
} from "swiper";
SwiperCore.use([FreeMode, Navigation, Thumbs, Pagination]);
@Component({
  selector: "app-success",
  templateUrl: "./success.component.html",
  styleUrls: ["./success.component.scss"],
})
export class SuccessComponent implements OnInit {
  contanctInfo: any;
  @Input() products: ExtractedData = {} as ExtractedData;
  @Input() singleorder: boolean = false;
  relatedProductsSliderConfig: SwiperOptions = {
    slidesPerView: "auto",
    spaceBetween: 0,
    navigation: true,
    scrollbar: { draggable: true },
  };
  constructor(
    private MasterApiService: MasterApiService,
    public commonService: CommonService,
    public productService: ProductService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.getAllContanctInfo();
  }

  getAllContanctInfo() {
    this.MasterApiService.getCurrentDataContact().subscribe(
      (dataContact) => {
        if (!dataContact) {
          console.error("Data contact information is undefined or null");
          return;
        }
        this.contanctInfo = dataContact;
      },
      (error) => {
        console.error("Error fetching contact info", error);
      }
    );
  }
  navigateTOOrder() {
    this.modalService.dismissAll();
  }

  getTotalQuantity(): number {
    return this.products.product_items.reduce(
      (total, product: any) => total + parseInt(product.quantity),
      0
    );
  }

  handleImageError(e) {
    e.src = "assets/images/product/placeholder2.jpg";
  }
}
