<app-breadcrumb
  [title]="page_name"
  [breadcrumb]="page_name"
  [currentUrl]="currentUrl"
></app-breadcrumb>
<!-- section start -->
<section class="cart-section section-b-space">
  <div class="container">
    <div class="loader-block" *ngIf="loading">
      <img src="assets/images/loader.gif" />
    </div>
    <div
      class="alert alert-warning d-flex justify-content-between align-items-baseline"
      role="alert"
      *ngIf="!loading && cart_products.length > 1"
      style="
        background-color: #fff3cd;
        border-radius: 10px;
        border: 1px solid #222;
      "
    >
      <p class="m-0 text-black font-weight-bold price-header">
        {{ noteTitle }}
      </p>
      <button
        class="m-0 btn btn-light"
        style="border-radius: 6px; border: 1px solid #222"
        (click)="openModalDetails(modal, layer)"
      >
        تفاصيل اكثر
      </button>
    </div>
    <div
      class="layer-modal"
      #layer
      (click)="onCloseModalDetails(modal, layer)"
    ></div>
    <div class="modal-container">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="modal" #modal>
              <div class="modal-content">
                <div
                  class="header-modal d-flex align-items-center justify-content-between"
                >
                  <h4 style="color: black">{{ noteTitle }}</h4>
                  <button
                    mat-button
                    (click)="onCloseModalDetails(modal, layer)"
                    style="background: transparent; border: none"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
              <div
                class="py-5 d-flex justify-content-center align-items-center"
              >
                <div id="note_description"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ? Collapse -->
    <div *ngFor="let cartitem of cart_products; let i = index" class="mb-3">
      <mat-accordion
        *ngIf="cart_products.length > 0 && !loading"
        class="mat-accordion-style"
      >
        <mat-expansion-panel
          [hideToggle]="true"
          class="pt-3 pb-3 bg-light"
          [(expanded)]="cartitem.panelOpenState"
          (opened)="cartitem.panelOpenState = true"
          (closed)="cartitem.panelOpenState = false"
          style="border: 1px solid #ccc"
        >
          <mat-expansion-panel-header>
            <mat-panel-title class="m-0 align-items-center">
              <div
                class="px-2 d-flex justify-content-between align-items-center w-100"
              >
                <div class="d-flex align-items-center">
                  <div class="logo-container">
                    <img
                      *ngIf="cartitem.details_store_external.id == '0'"
                      [src]="
                        Common._ImageUrl +
                        'front_images/' +
                        contactInfo.front_fav_icon
                      "
                      width="45"
                      style="padding: 4px"
                      height="45"
                      [border]="1"
                      alt=""
                      srcset=""
                    />
                    <img
                      *ngIf="cartitem.details_store_external.id != '0'"
                      [src]="
                        importApiImage + cartitem.details_store_external?.image
                      "
                      width="45"
                      style="padding: 4px"
                      height="45"
                      [border]="1"
                      alt=""
                      srcset=""
                    />
                    <img
                      *ngIf="
                        (cartitem.details_store_external.image == null &&
                          cartitem.details_store_external.id != '0') ||
                        (Common._ImageUrl +
                          'front_images/' +
                          contactInfo.front_fav_icon ==
                          null &&
                          cartitem.details_store_external.id == '0')
                      "
                      src="assets/images/product/placeholder.jpg"
                      width="45"
                      style="padding: 4px; margin-left: 10px"
                      height="45"
                      [border]="1"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <p
                    class="px-2 my-auto text-black font-weight-bold price-header"
                  >
                    {{ "order shipping" | translate }}
                    {{
                      cartitem.details_store_external.id == "0"
                        ? ("Warehouse" | translate)
                        : ""
                    }}
                    {{
                      cartitem.details_store_external.id == "0"
                        ? contactInfo.front_name
                        : cartitem.details_store_external.name
                    }}
                  </p>
                </div>
                <span class="font-weight-bold price-header"
                  >{{
                    +cartitem["total_price"] -
                      +cartitem["total_discount"] -
                      +cartitem["total_coupon_discount"] -
                      +cartitem["total_quantity_discount"] -
                      cartitem["total_offer_discount"] | number : "1.1-1"
                  }}
                  {{ productService?.Currency.name }}
                </span>
              </div>
            </mat-panel-title>
            <mat-panel-description>
              <div
                class="px-2 mt-3 d-flex align-items-center justify-content-between w-100"
              >
                <button
                  class="btn text-light del-products"
                  (click)="emptycart(i)"
                >
                  <span
                    *ngIf="
                      cartitem['invoice_edit_id'] &&
                      cartitem['invoice_edit_id'] == 0
                    "
                  >
                    {{ "Delete all products" | translate }}
                  </span>
                  <span
                    *ngIf="
                      cartitem['invoice_edit_id'] &&
                      cartitem['invoice_edit_id'] != 0
                    "
                  >
                    {{ "cancel edit" | translate }}
                  </span>
                </button>
                <span
                  *ngIf="cartitem.panelOpenState == true"
                  class="d-flex bg-dark text-light see-btn"
                  [class]="Common.isRtl ? 'see-padding-ar' : 'see-padding-en'"
                >
                  <span class="material-symbols-outlined">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </span>
                  <span>
                    {{ "see less" | translate }}
                  </span>
                </span>
                <span
                  *ngIf="cartitem.panelOpenState == false"
                  class="d-flex bg-dark text-light see-btn"
                  [class]="Common.isRtl ? 'see-padding-ar' : 'see-padding-en'"
                >
                  <span class="material-symbols-outlined">
                    <mat-icon>keyboard_arrow_up</mat-icon>
                  </span>
                  <span>
                    {{ "see more" | translate }}
                  </span>
                </span>
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="mt-2">
            <div
              class="edit-order-block d-flex justify-content-center align-items-center flex-column"
              *ngIf="
                cartitem['invoice_edit_id'] &&
                cartitem['invoice_edit_id'] != 0 &&
                !loading
              "
            >
              <span class="text-center">
                {{ "Order" | translate }} {{ "Number2" | translate }}
                {{ cartitem["count_invoice_type"] }}
                {{ "is under modification" | translate }}
                {{ "Now" | translate }}
              </span>
              <span class="text-center" style="color: red">
                {{ "Do" | translate }}{{ "confirm" | translate }}
                {{ "Order" | translate }}
                {{
                  "after
                                completing the modification to update"
                    | translate
                }}
              </span>
            </div>

            <div
              class="row"
              *ngIf="cartitem.product_items?.length > 0 && !loading"
            >
              <div class="col-sm-8">
                <div class="mobile-cart-layout">
                  <div
                    class="single-product-layout"
                    *ngFor="
                      let product of cartitem.product_items;
                      let i = index
                    "
                  >
                    <div class="single-product position-relative">
                      <div
                        class="top-0 left-0 layer position-absolute w-100 h-100 bg-dark"
                        *ngIf="
                          (product['active'] == 2 &&
                            product['invoice_edit_id'] == '0') ||
                          product['store_amounts_product'] < product['quantity']
                        "
                      ></div>
                      <div class="top-0 px-2 cover position-absolute start-0">
                        <span
                          *ngIf="
                            product['store_amounts_product'] <
                            product['quantity']
                          "
                        >
                          {{ "out of stock" | translate }}
                        </span>
                        <span
                          *ngIf="
                            product['active'] == 2 &&
                            product['invoice_edit_id'] == '0'
                          "
                        >
                          {{ "Inventory is in progress" | translate }}
                        </span>
                      </div>
                      <a
                        class="cart-link"
                        *ngIf="product?.images?.length > 0"
                        [routerLink]="[
                          '/shop/product/',
                          product?.product_id,
                          product.meta_url
                            ? product.meta_url?.split(' ').join('-')
                            : removeBrackets(
                                product.product_name ? product.product_name : ''
                              )
                                .split(' ')
                                .join('-')
                        ]"
                      >
                        <img
                          *ngIf="
                            product.image &&
                            product.images[0]['type'] === 'image'
                          "
                          [src]="urlimage + product.original_image"
                          [alt]="product?.product_name"
                          (error)="handleImageError($event.target)"
                          class="img-fluid"
                        />

                        <img
                          *ngIf="
                            product.original_image &&
                            product.images[0]['type'] === 'video'
                          "
                          src="assets/images/video.png"
                        />

                        <img
                          *ngIf="!product.original_image"
                          src="assets/images/product/placeholder.jpg"
                          [alt]="product?.product_name"
                        />
                      </a>
                      <a
                        class="cart-link"
                        [routerLink]="[
                          '/shop/product/',
                          product?.product_id,
                          product.meta_url !== null
                            ? product.meta_url?.split(' ').join('-')
                            : removeBrackets(
                                product.product_name ? product.product_name : ''
                              )
                                .split(' ')
                                .join('-')
                        ]"
                        *ngIf="product?.images?.length == 0"
                      >
                        <img
                          src="assets/images/product/placeholder.jpg"
                          class="product-image"
                        />
                      </a>

                      <div
                        class="title-quantity-price-block"
                        #offerDataContainerMobile
                      >
                        <div class="title">
                          <a
                            [routerLink]="[
                              '/shop/product/',
                              product?.product_id,
                              product.meta_url
                                ? product.meta_url.split(' ').join('-')
                                : removeBrackets(
                                    product.product_name
                                      ? product.product_name
                                      : ''
                                  )
                                    .split(' ')
                                    .join('-')
                            ]"
                          >
                            {{
                              product?.product_name
                                ? product?.product_name
                                : ("" | titlecase)
                            }}
                          </a>
                        </div>
                        <div class="d-flex flex-column">
                          <div
                            class="d-flex align-items-start justify-content-between"
                          >
                            <div
                              *ngIf="product?.seller_name != ''"
                              class="text-start"
                            >
                              <span class="seller-data">
                                {{ "seller" | translate }} :
                                {{ product?.seller_name }}
                              </span>
                            </div>
                            <div
                              class="image-shipping"
                              style="height: auto !important"
                            >
                              <img
                                *ngIf="
                                  product.product_group_data &&
                                  product.product_group_data.img
                                "
                                [src]="
                                  Common._ImageUrl +
                                  'groups/' +
                                  product.product_group_data.img
                                "
                                alt="Shipping image"
                                width="auto"
                                height="20"
                                (error)="handleImageError($event)"
                              />
                              <div class="w-100">
                                <span
                                  *ngIf="
                                    product.product_group_data &&
                                    product.product_group_data.img &&
                                    +product.product_group_data
                                      .delivery_price == 0 &&
                                    +product.product_group_data.order_price > 0
                                  "
                                >
                                  <i class="fa fa-car"></i>
                                  {{ "free_shipping" | translate }}</span
                                >
                                <span
                                  *ngIf="
                                    product.product_group_data &&
                                    product.product_group_data.img &&
                                    +product.product_group_data.delivery_price >
                                      0 &&
                                    +product.product_group_data.order_price > 0
                                  "
                                >
                                  <i class="fa fa-car"></i>
                                  {{ "discounted_shipping" | translate }}</span
                                >
                              </div>
                            </div>
                          </div>

                          <div
                            class="mb-2"
                            *ngIf="product.tags.length > 0"
                            [style.width]="containerMobile"
                          >
                            <app-item-slider
                              [items]="product?.tags"
                              [itemStyle]="{
                                'font-size': '13px',
                                'font-weight': '400'
                              }"
                            ></app-item-slider>
                          </div>

                          <div
                            class="offer-data-container1 w-100 alpha-discount-bg"
                            *ngIf="
                              product?.last_price > 0 &&
                              +product?.last_price > +product?.price &&
                              product.quantity_last_price == null
                            "
                          >
                            <span
                              class="discount-data"
                              [style.color]="
                                calculateTextColor('--theme-default')
                              "
                              ><b
                                >{{
                                  "Reduced
                                                                discount"
                                    | translate
                                }}
                                {{
                                  getDiscountPercentage(
                                    product?.last_price,
                                    product?.price
                                  ) + "%"
                                }}</b
                              >
                            </span>

                            <span
                              *ngIf="
                                product?.price > 0 && product?.last_price > 0
                              "
                              class="mx-2 text-black offer-attachment_single-container"
                            >
                              <b class="percent-discount">
                                -{{
                                  getSubtraction(
                                    product?.last_price,
                                    product?.price,
                                    product?.quantity
                                  )
                                }}
                                {{ productService?.Currency.name }}
                              </b>
                            </span>
                          </div>
                          <div
                            class="offer-data-container1 w-100 alpha-discount-bg"
                            *ngIf="
                              product?.last_price > 0 &&
                              +product?.last_price > +product?.price &&
                              +product.quantity_last_price > 0
                            "
                          >
                            <span
                              class="discount-data"
                              [style.color]="
                                calculateTextColor('--theme-default')
                              "
                              ><b>
                                {{
                                  "Reduced
                                                                discount"
                                    | translate
                                }}
                                {{
                                  getDiscountPercentage(
                                    product?.last_price,
                                    product?.quantity_last_price
                                  ) + "%"
                                }}</b
                              >
                            </span>
                            <span
                              *ngIf="
                                product?.price > 0 && product?.last_price > 0
                              "
                              class="mx-2 text-black d-block offer-attachment_single-container"
                            >
                              <b class="percent-discount">
                                -{{
                                  getSubtraction(
                                    product?.last_price,
                                    product?.quantity_last_price,
                                    product?.quantity
                                  )
                                }}
                                {{ productService?.Currency.name }}
                              </b>
                            </span>
                          </div>
                          <div
                            class="offer-data-container1 alpha-quantity-discount-bg w-100"
                            *ngIf="
                              product?.price > 0 &&
                              product.quantity_last_price != null
                            "
                          >
                            <span class="quantitydiscount-tag"
                              ><b>{{ "quantitydiscount" | translate }} </b>
                            </span>
                            <span
                              *ngIf="
                                product?.price > 0 &&
                                +product.quantity_last_price > 0
                              "
                              class="mx-2 text-black offer-attachment_single-container"
                            >
                              <b
                                >-{{
                                  getSubtraction(
                                    product?.quantity_last_price,
                                    product?.price,
                                    product?.quantity
                                  )
                                }}
                                {{ productService?.Currency.name }}</b
                              >
                            </span>
                          </div>

                          <div
                            *ngIf="isSmallScreen"
                            #outerDiv
                            style="max-width: 100%; display: block"
                            class="offer-mobile"
                          >
                            <div
                              #innerDiv
                              class="offer-data-container1 offer-mobile-style alpha-offer-bg w-100"
                              *ngIf="
                                product?.offer_id > 0 && product?.offer_data
                              "
                            >
                              <span
                                class="offer-data"
                                [style.color]="
                                  calculateTextColor('--theme-default2')
                                "
                              >
                                <b style="white-space: nowrap">
                                  {{ product?.offer_data["title"] }}
                                </b>
                              </span>
                              <div
                                #textContainer
                                *ngIf="product?.offer_data['attachment_single']"
                                class="mx-2 text-black offer-data-attachment-single-mobile"
                              >
                                {{ product?.offer_data["attachment_single"] }}
                              </div>
                              <div
                                *ngIf="
                                  isTextOverflowing &&
                                  product?.offer_data['attachment_single']
                                "
                                class="cursor-pointer text-overflow-info"
                                (click)="
                                  openModalTextOverflow(
                                    product?.offer_data['title'],
                                    product?.offer_data['attachment_single']
                                  )
                                "
                              >
                                &lt;
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="quantity-price-block">
                          <div class="price-block">
                            <span class="price">
                              {{
                                (product?.price | discount : product) *
                                  productService?.Currency.price
                              }}
                              {{ productService?.Currency.name }}
                            </span>
                            <del
                              *ngIf="
                                product?.price > 0 &&
                                  ((product?.last_price > 0 &&
                                    +product?.quantity_last_price > 0) ||
                                    product?.last_price > 0);
                                else elselastPrice
                              "
                              class="old-price"
                            >
                              {{ product?.last_price }}
                              {{ productService?.Currency.name }}
                            </del>

                            <ng-template #elselastPrice>
                              <del
                                *ngIf="
                                  product?.price > 0 &&
                                  +product?.quantity_last_price > 0
                                "
                                class="old-price"
                              >
                                {{ product?.quantity_last_price }}
                                {{ productService?.Currency.name }}
                              </del>
                            </ng-template>
                          </div>

                          <div
                            class="quantity-block align-items-start"
                            *ngIf="product?.active == 2"
                          >
                            <span class="text-danger">{{
                              "Inventory is in progress" | translate
                            }}</span>
                          </div>
                          <div
                            class="quantity-block align-items-start"
                            *ngIf="product?.active != 2"
                          >
                            <div
                              class="increase-decrease-button"
                              (click)="decrement(product, qtn, loader)"
                            >
                              <i class="fa fa-minus" aria-hidden="true"></i>
                            </div>
                            <div class="quantity-number-block">
                              <span class="quantity-number" #qtn>{{
                                product.quantity
                              }}</span>
                              <div
                                class="mx-auto loader-block d-none w-100"
                                #loader
                              >
                                <img
                                  class="loader-gif"
                                  src="assets/images/loader.gif"
                                />
                              </div>
                            </div>
                            <div
                              class="increase-decrease-button"
                              (click)="increment(product, qtn, loader)"
                            >
                              <i class="fa fa-plus" aria-hidden="true"></i>
                            </div>
                          </div>

                          <div class="text-right total-price-block">
                            <span class="total-price">
                              {{
                                (product?.price | discount : product) *
                                  product?.quantity *
                                  productService?.Currency.price
                              }}
                              {{ productService?.Currency.name }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="p-2 cell-footer d-flex justify-content-between"
                      [class.noContent]="
                        (product['active'] == 2 &&
                          product['invoice_edit_id'] == '0') ||
                        product['store_amounts_product'] < product['quantity']
                      "
                    >
                      <div>
                        <div
                          *ngIf="
                            (cart_products[0]['invoice_edit_id'] == '0' ||
                              (product?.active != 2 &&
                                product['invoice_edit_id'] != '0')) &&
                            !loadingStates[product.product_id]
                          "
                          class="options-block btn-container-cart"
                          (click)="
                            removeItem(product, cartitem['id'], cartitem)
                          "
                        >
                          <i class="fa fa-trash" aria-hidden="true"></i>
                          {{ "Delete" | translate }}
                        </div>
                        <div *ngIf="loadingStates[product.product_id]">
                          <img
                            src="assets/images/loader.gif"
                            width="30px"
                            height="30px"
                          />
                        </div>
                      </div>
                      <div
                        *ngIf="
                          product['store_amounts_product'] < product['quantity']
                        "
                        class="cursor-pointer d-flex align-items-center white-space-no-wrap btn-container-cart"
                        (click)="
                          openNotifyMeModal(notifyModal, product?.product_id)
                        "
                      >
                        <i class="fa fa-bell" aria-hidden="true"></i>
                        {{ "Notify me" | translate }}
                      </div>
                      <div class="cursor-pointer favorite">
                        <span
                          class="d-flex align-items-center btn-container-cart"
                          *ngIf="!product.isFavourit"
                          (click)="addToWishlist(product)"
                        >
                          <i class="fa fa-heart-o heart" aria-hidden="true"></i>
                          <span class="white-space-no-wrap">
                            {{ "Add To WishList" | translate }}
                          </span>
                        </span>
                        <span
                          class="d-flex align-items-center btn-container-cart"
                          *ngIf="product.isFavourit"
                        >
                          <i
                            class="fa fa-heart heart text-danger"
                            aria-hidden="true"
                          ></i>
                          <span class="white-space-no-wrap">
                            {{ "Add To WishList" | translate }}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cart-table-bordered">
                  <table class="table cart-table table-responsive-xs">
                    <tbody>
                      <tr
                        *ngFor="
                          let product of cartitem.product_items;
                          let index = index
                        "
                      >
                        <div
                          class="table-contain d-flex flex-column w-100 position-relative"
                        >
                          <div
                            class="top-0 left-0 layer position-absolute w-100 h-100 bg-dark"
                            *ngIf="
                              (product['active'] == 2 &&
                                product['invoice_edit_id'] == '0') ||
                              product['store_amounts_product'] <
                                product['quantity']
                            "
                          ></div>
                          <div
                            class="top-0 px-2 cover position-absolute start-0"
                          >
                            <span
                              *ngIf="
                                product['store_amounts_product'] <
                                product['quantity']
                              "
                            >
                              {{ "out of stock" | translate }}
                            </span>
                            <span
                              *ngIf="
                                product['active'] == 2 &&
                                product['invoice_edit_id'] == '0'
                              "
                            >
                              {{ "Inventory is in progress" | translate }}
                            </span>
                          </div>
                          <div class="px-3 table-tr">
                            <td class="image-cell">
                              <div class="lable-block">
                                <span
                                  #label
                                  class="lable-outofstock"
                                  *ngIf="
                                    (product.store_amounts == 0 &&
                                      product.price == 0) ||
                                    (product.store_amounts == 0 &&
                                      product.price > 0)
                                  "
                                  [class]="
                                    Common.isRtl ? 'label-rtl' : 'label-ltr'
                                  "
                                >
                                  {{ "out of stock" | translate }}
                                </span>
                              </div>
                              <a
                                [routerLink]="[
                                  '/shop/product/',
                                  product?.product_id,
                                  product.meta_url
                                    ? product.meta_url?.split(' ').join('-')
                                    : removeBrackets(
                                        product.product_name
                                          ? product.product_name
                                          : ''
                                      )
                                        .split(' ')
                                        .join('-')
                                ]"
                                *ngIf="product?.images.length > 0"
                              >
                                <img
                                  *ngIf="
                                    product.image &&
                                    product.images[0]['type'] === 'image'
                                  "
                                  [src]="urlimage + product.original_image"
                                  [alt]="product?.product_name"
                                  (error)="handleImageError($event.target)"
                                />

                                <img
                                  *ngIf="
                                    product.image &&
                                    product.images[0]['type'] === 'video'
                                  "
                                  src="assets/images/video.png"
                                />

                                <img
                                  *ngIf="!product.original_image"
                                  src="assets/images/product/placeholder.jpg"
                                  [alt]="product?.product_name"
                                />
                              </a>
                              <a
                                [routerLink]="[
                                  '/shop/product/',
                                  product?.product_id,
                                  product.meta_url
                                    ? product.meta_url?.split(' ').join('-')
                                    : removeBrackets(
                                        product.product_name
                                          ? product.product_name
                                          : ''
                                      )
                                        .split(' ')
                                        .join('-')
                                ]"
                                *ngIf="product?.images?.length == 0"
                              >
                              </a>
                              <a
                                [routerLink]="[
                                  '/shop/product/',
                                  product?.product_id,
                                  product.meta_url
                                    ? product.meta_url?.split(' ').join('-')
                                    : removeBrackets(
                                        product.product_name
                                          ? product.product_name
                                          : ''
                                      )
                                        .split(' ')
                                        .join('-')
                                ]"
                                *ngIf="product?.images.length == 0"
                              >
                                <img
                                  src="assets/images/product/placeholder.jpg"
                                />
                              </a>
                            </td>
                            <td style="width: 100%">
                              <div
                                class="offer-data-container"
                                #offerDataContainer
                              >
                                <a
                                  [routerLink]="[
                                    '/shop/product/',
                                    product?.product_id,
                                    product.meta_url
                                      ? product.meta_url?.split(' ').join('-')
                                      : removeBrackets(
                                          product.product_name
                                            ? product.product_name
                                            : ''
                                        )
                                          .split(' ')
                                          .join('-')
                                  ]"
                                  >{{
                                    product?.product_name
                                      ? product?.product_name
                                      : ("" | titlecase)
                                  }}</a
                                >
                              </div>

                              <div class="offer-data-container1">
                                <div
                                  *ngIf="product?.seller_name != ''"
                                  class="text-start"
                                >
                                  <span class="seller-data">
                                    {{ "seller" | translate }} :
                                    {{ product?.seller_name }}
                                  </span>
                                </div>
                              </div>

                              <div
                                *ngIf="product.tags.length > 0"
                                [style.width]="containerWidth"
                              >
                                <app-item-slider
                                  [items]="product?.tags"
                                  [itemStyle]="{
                                    'font-size': '13px',
                                    'font-weight': '400'
                                  }"
                                ></app-item-slider>
                              </div>
                              <div class="mobile-cart-content row">
                                <div class="col-xs-3">
                                  <div class="qty-box">
                                    <div class="input-group">
                                      <input
                                        type="text"
                                        name="quantity"
                                        class="form-control input-number"
                                        disabled
                                        [(ngModel)]="product.quantity"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-xs-3 price">
                                  <h2 class="td-color">
                                    {{
                                      (product?.price | discount : product) *
                                        productService?.Currency.price
                                    }}
                                    {{ productService?.Currency.name }}
                                  </h2>
                                </div>
                                <div class="col-xs-3">
                                  <h2 class="td-color">
                                    <a
                                      *ngIf="
                                        (product['invoice_edit_id'] == '0' ||
                                          (product.active != 2 &&
                                            product['invoice_edit_id'] !=
                                              '0')) &&
                                        !loadingStates[product.product_id]
                                      "
                                      (click)="
                                        removeItem(
                                          product,
                                          cartitem['id'],
                                          cartitem
                                        )
                                      "
                                      class="icon"
                                    >
                                      <i
                                        class="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                    <div
                                      *ngIf="loadingStates[product.product_id]"
                                    >
                                      <img
                                        src="assets/images/loader.gif"
                                        width="30px"
                                        height="30px"
                                      />
                                    </div>
                                  </h2>
                                </div>
                              </div>
                            </td>

                            <td style="min-width: 350px">
                              <div
                                class="offer-data-container1 w-100 alpha-discount-bg"
                                *ngIf="
                                  product?.last_price > 0 &&
                                  +product?.last_price > +product?.price &&
                                  product.quantity_last_price == null
                                "
                              >
                                <span
                                  class="discount-data"
                                  [style.color]="
                                    calculateTextColor('--theme-default')
                                  "
                                  ><b
                                    >{{
                                      "Reduced
                                                                            discount"
                                        | translate
                                    }}
                                    {{
                                      getDiscountPercentage(
                                        product?.last_price,
                                        product?.price
                                      ) + "%"
                                    }}</b
                                  >
                                </span>

                                <span
                                  *ngIf="
                                    product?.price > 0 &&
                                    product?.last_price > 0
                                  "
                                  class="mx-2 text-black offer-attachment_single-container"
                                >
                                  <b class="percent-discount">
                                    -{{
                                      getSubtraction(
                                        product?.last_price,
                                        product?.price,
                                        product?.quantity
                                      )
                                    }}
                                    {{ productService?.Currency.name }}
                                  </b>
                                </span>
                              </div>
                              <div
                                class="offer-data-container1 w-100 alpha-discount-bg"
                                *ngIf="
                                  product?.last_price > 0 &&
                                  +product?.last_price > +product?.price &&
                                  +product.quantity_last_price > 0
                                "
                              >
                                <span
                                  class="discount-data"
                                  [style.color]="
                                    calculateTextColor('--theme-default')
                                  "
                                  ><b>
                                    {{
                                      "Reduced
                                                                            discount"
                                        | translate
                                    }}
                                    {{
                                      getDiscountPercentage(
                                        product?.last_price,
                                        product?.quantity_last_price
                                      ) + "%"
                                    }}</b
                                  >
                                </span>
                                <span
                                  *ngIf="
                                    product?.price > 0 &&
                                    product?.last_price > 0
                                  "
                                  class="mx-2 text-black d-block offer-attachment_single-container"
                                >
                                  <b class="percent-discount">
                                    -{{
                                      getSubtraction(
                                        product?.last_price,
                                        product?.quantity_last_price,
                                        product?.quantity
                                      )
                                    }}
                                    {{ productService?.Currency.name }}
                                  </b>
                                </span>
                              </div>
                              <div
                                class="offer-data-container1 alpha-quantity-discount-bg w-100"
                                *ngIf="
                                  product?.price > 0 &&
                                  product.quantity_last_price != null
                                "
                              >
                                <span class="quantitydiscount-tag"
                                  ><b>{{ "quantitydiscount" | translate }} </b>
                                </span>
                                <span
                                  *ngIf="
                                    product?.price > 0 &&
                                    +product.quantity_last_price > 0
                                  "
                                  class="mx-2 text-black offer-attachment_single-container"
                                >
                                  <b
                                    >-{{
                                      getSubtraction(
                                        product?.quantity_last_price,
                                        product?.price,
                                        product?.quantity
                                      )
                                    }}
                                    {{ productService?.Currency.name }}</b
                                  >
                                </span>
                              </div>

                              <div
                                #outerDiv
                                style="max-width: 100%; display: block"
                              >
                                <div
                                  #innerDiv
                                  class="offer-data-container1 offer-mobile-style alpha-offer-bg w-100"
                                  *ngIf="
                                    product?.offer_id > 0 && product?.offer_data
                                  "
                                >
                                  <span
                                    class="offer-data"
                                    [style.color]="
                                      calculateTextColor('--theme-default2')
                                    "
                                  >
                                    <b style="white-space: nowrap">
                                      {{ product?.offer_data["title"] }}
                                    </b>
                                  </span>
                                  <div
                                    #textContainer
                                    *ngIf="
                                      product?.offer_data['attachment_single']
                                    "
                                    class="mx-2 text-black offer-data-attachment-single"
                                  >
                                    {{
                                      product?.offer_data["attachment_single"]
                                    }}
                                  </div>
                                  <div
                                    *ngIf="
                                      isTextOverflowing &&
                                      product?.offer_data['attachment_single']
                                    "
                                    class="cursor-pointer text-overflow-info"
                                    (click)="
                                      openModalTextOverflow(
                                        product?.offer_data['title'],
                                        product?.offer_data['attachment_single']
                                      )
                                    "
                                  >
                                    &lt;
                                  </div>
                                </div>
                              </div>

                              <div
                                class="offer-data-container1 alpha-discount-bg justify-content-start gap-5 w-100"
                                *ngIf="product?.offer_data_item"
                              >
                                <span
                                  class="discount-data"
                                  [style.color]="
                                    calculateTextColor('--theme-default')
                                  "
                                  ><b>{{ "offer_applied" | translate }} </b>
                                </span>
                                <span
                                  class="mx-2 text-black offer-attachment_single-container"
                                >
                                  <b
                                    class="percent-discount"
                                    *ngIf="
                                      product?.offer_data_item['offer_type'] ==
                                      'percentage'
                                    "
                                  >
                                    {{ "discount" | translate }}
                                    {{ product?.offer_data_item["percentage"] }}
                                    %
                                    {{ "on" | translate }}
                                    {{ product?.offer_data_item["count"] }}
                                    {{ "a_piece" | translate }}
                                  </b>
                                  <b
                                    class="percent-discount"
                                    *ngIf="
                                      product?.offer_data_item['offer_type'] ==
                                      'free'
                                    "
                                  >
                                    {{ product?.offer_data_item["count"] }}
                                    {{ "a_piece" | translate }}
                                    {{ "free" | translate }}
                                  </b>
                                </span>
                              </div>

                              <div
                                class="offer-data-container2 w-100 d-flex justify-content-between align-items-center"
                              >
                                <h2
                                  class="td-color text-start whitespace-nowrap"
                                >
                                  {{
                                    (product.price | discount : product) *
                                      productService?.Currency.price
                                  }}
                                  {{ productService?.Currency.name }}
                                  <!-- <del
                                    *ngIf="
                                      product?.price > 0 &&
                                      product?.last_price > 0
                                    "
                                    class="pt-1 d-block"
                                    >{{ product?.last_price }}
                                    {{ productService?.Currency.name }}</del
                                  > -->

                                  <del
                                    *ngIf="
                                      product?.price > 0 &&
                                        ((product?.last_price > 0 &&
                                          +product?.quantity_last_price > 0) ||
                                          product?.last_price > 0);
                                      else elselastPrice
                                    "
                                    class="pt-1 d-block"
                                  >
                                    {{ product?.last_price }}
                                    {{ productService?.Currency.name }}
                                  </del>

                                  <ng-template #elselastPrice>
                                    <del
                                      *ngIf="
                                        product?.price > 0 &&
                                        +product?.quantity_last_price > 0
                                      "
                                      class="pt-1 d-block"
                                    >
                                      {{ product?.quantity_last_price }}
                                      {{ productService?.Currency.name }}
                                    </del>
                                  </ng-template>
                                </h2>

                                <div
                                  class="quantity-box"
                                  *ngIf="product.active == 2"
                                >
                                  <span class="text-danger">
                                    {{ "Inventory is in progress" | translate }}
                                  </span>
                                </div>

                                <div
                                  class="quantity-box"
                                  *ngIf="product.active != 2"
                                >
                                  <button
                                    [disabled]="disabledplus"
                                    data-type="plus"
                                    (click)="increment(product, qtn, loader)"
                                  >
                                    <i class="ti-plus"></i>
                                  </button>
                                  <input
                                    type="text"
                                    name="quantity"
                                    #qtn
                                    [(ngModel)]="product.quantity"
                                  />
                                  <div class="loader-block d-none" #loader>
                                    <img
                                      src="assets/images/loader.gif"
                                      width="30px"
                                      height="30px"
                                    />
                                  </div>
                                  <button
                                    [disabled]="disabledminus"
                                    data-type="minus"
                                    (click)="decrement(product, qtn, loader)"
                                  >
                                    <i class="ti-minus"></i>
                                  </button>
                                </div>
                                <h2 class="td-color whitespace-nowrap">
                                  {{
                                    (product.price | discount : product) *
                                      product.quantity *
                                      productService?.Currency.price
                                  }}
                                  {{ productService?.Currency.name }}
                                </h2>
                              </div>
                            </td>
                          </div>
                          <div
                            class="px-3 py-2 cell-footer position-relative"
                            [class.noContent]="
                              (product['active'] == 2 &&
                                product['invoice_edit_id'] == '0') ||
                              product['store_amounts_product'] <
                                product['quantity']
                            "
                            style="border-bottom: 1px solid #e2e2e2"
                          >
                            <div class="cart-delete-wishlist">
                              <div>
                                <a
                                  href="javascript:void(0)"
                                  (click)="
                                    removeItem(
                                      product,
                                      cartitem['id'],
                                      cartitem
                                    )
                                  "
                                  *ngIf="
                                    (product['invoice_edit_id'] == '0' ||
                                      (product.active != 2 &&
                                        product['invoice_edit_id'] != '0')) &&
                                    !loadingStates[product.product_id]
                                  "
                                  class="icon delete-item-icon-block d-flex align-items-center btn-container-cart"
                                >
                                  <i
                                    class="fa fa-trash text-red"
                                    aria-hidden="true"
                                  ></i>
                                  <span>
                                    {{ "Delete" | translate }}
                                  </span>
                                </a>
                                <div *ngIf="loadingStates[product.product_id]">
                                  <img
                                    src="assets/images/loader.gif"
                                    width="30px"
                                    height="30px"
                                  />
                                </div>
                              </div>
                              <div
                                *ngIf="
                                  product['store_amounts_product'] <
                                  product['quantity']
                                "
                                class="cursor-pointe d-flex align-items-center white-space-no-wrap btn-container-cart"
                                (click)="
                                  openNotifyMeModal(
                                    notifyModal,
                                    product?.product_id
                                  )
                                "
                              >
                                <i class="fa fa-bell" aria-hidden="true"></i>
                                {{ "Notify me" | translate }}
                              </div>
                              <div class="cursor-pointer favorite">
                                <span
                                  *ngIf="!product.isFavourit"
                                  (click)="addToWishlist(product)"
                                  class="d-flex align-items-center btn-container-cart"
                                >
                                  <i
                                    class="fa fa-heart-o heart"
                                    aria-hidden="true"
                                  ></i>
                                  <span class="white-space-no-wrap">
                                    {{ "Add To WishList" | translate }}
                                  </span>
                                </span>
                                <span
                                  class="d-flex align-items-center btn-container-cart"
                                  *ngIf="product.isFavourit"
                                >
                                  <i
                                    class="fa fa-heart heart text-danger"
                                    aria-hidden="true"
                                  ></i>
                                  <span class="white-space-no-wrap">
                                    {{ "Add To WishList" | translate }}
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div class="image-shipping">
                              <span
                                *ngIf="
                                  product.product_group_data &&
                                  product.product_group_data.img &&
                                  +product.product_group_data.delivery_price ==
                                    0 &&
                                  +product.product_group_data.order_price > 0
                                "
                              >
                                <i class="fa fa-car"></i>
                                {{ "free_shipping" | translate }}</span
                              >
                              <span
                                *ngIf="
                                  product.product_group_data &&
                                  product.product_group_data.img &&
                                  +product.product_group_data.delivery_price >
                                    0 &&
                                  +product.product_group_data.order_price > 0
                                "
                              >
                                <i class="fa fa-car"></i>
                                {{ "discounted_shipping" | translate }}</span
                              >
                              <img
                                *ngIf="
                                  product.product_group_data &&
                                  product.product_group_data.img
                                "
                                [src]="
                                  Common._ImageUrl +
                                  'groups/' +
                                  product.product_group_data.img
                                "
                                alt="Shipping image"
                                width="auto"
                                height="20"
                                (error)="handleImageError($event)"
                              />
                            </div>
                          </div>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-sm-4">
                <div class="cart_confirm">
                  <h2 class="title">{{ "order summary" | translate }}</h2>

                  <div
                    class="coupons-checker-layout"
                    *ngIf="cartitem['api_id_to'] == 0"
                  >
                    <div class="coupons-upper-text-block">
                      <span class="coupon-question">
                        {{ "got a coupon" | translate }}
                      </span>
                      <span
                        class="coupon-showall"
                        *ngIf="!showCouponsList"
                        (click)="showCouponsList = true"
                      >
                        {{ "show coupons" | translate }}
                      </span>
                      <!-- <i class='ti-close' *ngIf="showCouponsList" style="cursor: pointer"
                                (click)="showCouponsList = false"></i> -->

                      <i
                        *ngIf="showCouponsList"
                        (click)="showCouponsList = false"
                        style="cursor: pointer"
                        class="fa fa-times"
                        aria-hidden="true"
                      >
                      </i>
                    </div>
                    <div class="coupons-list-all" *ngIf="showCouponsList">
                      <span
                        class="error-text"
                        *ngIf="activeCouponesList.length == 0"
                      >
                        {{ "no coupones found" | translate }}
                      </span>

                      <div
                        class="coupone-block"
                        *ngFor="
                          let coupone of activeCouponesList;
                          let index = index
                        "
                      >
                        <div class="start-side">
                          <div class="coupon-title">
                            {{ coupone.title }}
                          </div>
                          <div class="code-box">
                            {{ coupone.code }}
                          </div>
                        </div>
                        <div
                          class="mid-side"
                          *ngIf="getCouponeAccordionStatus(index)"
                        >
                          <span
                            class="sub-title"
                            [innerHTML]="coupone.description"
                          >
                          </span>
                        </div>
                        <div class="end-side">
                          <div
                            class="more-details"
                            (click)="openCouponeDescription(index)"
                          >
                            <span *ngIf="!getCouponeAccordionStatus(index)">
                              {{ "more details" | translate }}
                            </span>
                            <span *ngIf="getCouponeAccordionStatus(index)">
                              {{ "hide details" | translate }}
                            </span>
                          </div>
                          <div
                            class="confirm-coupon"
                            (click)="
                              !cartInfoDetailsLoading &&
                                confirmCouponToInput(
                                  coupone.code,
                                  cartitem['id']
                                )
                            "
                          >
                            {{ "click to confirm" | translate }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="coupons-checker-block"
                      [class.rtl]="Common.isRtl"
                      [class.ltr]="!Common.isRtl"
                    >
                      <input
                        type="text"
                        [ngClass]="isCouponAdded ? 'coupon-added' : ''"
                        placeholder="{{ 'enter code here' | translate }}"
                        [readonly]="isCouponAdded"
                        [(ngModel)]="codeValueCoupon"
                        id="fname"
                        name="fname"
                      />
                      <div
                        class="check-coupon"
                        (click)="addCoupon(codeValueCoupon, cartitem['id'])"
                        *ngIf="!isCouponAdded"
                      >
                        {{ "activate" | translate }}
                      </div>
                      <div
                        class="check-coupon"
                        (click)="deleteCoupon(cartitem['id'])"
                        *ngIf="isCouponAdded"
                      >
                        <i class="ti-trash"></i>
                      </div>
                    </div>
                  </div>
                  <ul class="cart-info-details" *ngIf="!cartInfoDetailsLoading">
                    <li class="">
                      <span>
                        {{ cartitem["total_product_count"] }}
                        {{ "item" | translate }} :
                      </span>
                      <span
                        >{{ cartitem["total_price"] }}
                        {{ productService?.Currency.name }}</span
                      >
                    </li>
                    <li
                      class="offer-data-container1 w-100 alpha-discount-bg"
                      *ngIf="cartitem['total_discount'] > 0"
                    >
                      <span
                        class="discount-data"
                        [style.color]="calculateTextColor('--theme-default')"
                        ><b>{{ "Discounts" | translate }}</b>
                      </span>

                      <span
                        class="mx-2 text-black offer-attachment_single-container"
                      >
                        <b class="percent-discount fw-bolder">
                          -{{ Common.formatNumber(cartitem["total_discount"]) }}
                          {{ productService?.Currency.name }}
                        </b>
                      </span>
                    </li>
                    <li
                      class="offer-data-container1 w-100 alpha-quantity-discount-bg"
                      *ngIf="cartitem['total_quantity_discount'] > 0"
                    >
                      <span class="quantitydiscount-tag"
                        ><b>{{ "quantitydiscount" | translate }}</b>
                      </span>

                      <span
                        class="mx-2 text-black offer-attachment_single-container"
                      >
                        <b class="fw-bolder">
                          -{{
                            Common.formatNumber(
                              cartitem["total_quantity_discount"]
                            )
                          }}
                          {{ productService?.Currency.name }}
                        </b>
                      </span>
                    </li>

                    <li
                      class="offer-data-container1 w-100 alpha-offer-bg"
                      *ngIf="cartitem['total_offer_discount'] > 0"
                    >
                      <span
                        class="offer-data"
                        [style.color]="calculateTextColor('--theme-default2')"
                        ><b>{{ "offer-discount" | translate }}</b>
                      </span>

                      <span
                        class="mx-2 text-black offer-attachment_single-container"
                      >
                        <b class="fw-bolder">
                          -{{ cartitem["total_offer_discount"] }}
                          {{ productService?.Currency.name }}
                        </b>
                      </span>
                    </li>

                    <li
                      class="td-color-c"
                      *ngIf="
                        (codeValueCoupon ||
                          cartitem['total_coupon_discount'] != '0.0') &&
                        cartitem['api_id_to'] == 0
                      "
                    >
                      <span class="d-flex flex-column align-items-start">
                        <span>
                          {{ "coupon discount" | translate }} ({{
                            codeValueCoupon
                          }}) :
                        </span>
                        <span
                          class="coupon-condition coupon-avaiable"
                          *ngIf="
                            isCouponAdded &&
                            cartitem['total_coupon_discount'] == '0.0'
                          "
                        >
                          {{ "requirements_not_met" | translate }}
                        </span>
                      </span>
                      <span
                        >-{{
                          Common.formatNumber(cartitem["total_coupon_discount"])
                        }}
                        {{ productService?.Currency.name }}</span
                      >
                    </li>
                    <li>
                      <span>{{ "Total-final" | translate }} : </span>
                      <span
                        >{{
                          cartitem["total_price"] -
                            cartitem["total_coupon_discount"] -
                            cartitem["total_discount"] -
                            cartitem["total_quantity_discount"] -
                            cartitem["total_offer_discount"] | number : "1.1-1"
                        }}
                        {{ productService?.Currency.name }}</span
                      >
                    </li>
                  </ul>
                  <div class="loader-block" *ngIf="cartInfoDetailsLoading">
                    <img src="assets/images/loader.gif" />
                  </div>
                  <div
                    class="checkout-btn p-0 m-0"
                    *ngIf="cart_products.length > 0"
                  >
                    <a
                      (click)="isLogin(cartitem)"
                      class="btn btn-solid w-100"
                      >{{ "checkout" | translate }}</a
                    >
                  </div>
                </div>

                <ng-container
                  *ngIf="
                    cart_products[i] &&
                    cart_products[i].cart_products_groups.length > 0
                  "
                >
                  <ng-container
                    *ngFor="let group of cart_products[i].cart_products_groups"
                  >
                    <div
                      class="p-2 my-2 bg-white padding-c"
                      *ngIf="+group.order_price > 0"
                    >
                      <div
                        class="flex-wrap mb-2 d-flex align-items-center justify-content-between"
                      >
                        <p
                          class="m-0 text-black"
                          *ngIf="+group.delivery_price == 0"
                        >
                          <span
                            *ngIf="+group.total_price >= +group.order_price"
                          >
                            {{ "con_shipping" | translate }}
                            <span style="color: red">{{
                              "shipping_free" | translate
                            }}</span>
                            <img
                              class="mr-1"
                              src="./assets/images/available.webp"
                              width="20"
                              alt="free shipping"
                            />
                          </span>
                          <span *ngIf="+group.total_price < +group.order_price">
                            {{
                              "remaining_amount_for_free_shipping"
                                | translate
                                  : {
                                      remaining_amount:
                                        +group.order_price - +group.total_price,
                                      currency: productService?.Currency.name
                                    }
                            }}
                          </span>
                        </p>
                        <p
                          class="m-0 text-black"
                          *ngIf="+group.delivery_price > 0"
                        >
                          <span
                            *ngIf="+group.total_price >= +group.order_price"
                          >
                            {{ "con_shipping" | translate }}
                            <span style="color: red">{{
                              "shipping_discount" | translate
                            }}</span>
                            <img
                              src="./assets/images/available.webp"
                              width="20"
                              alt="free shipping"
                              class="mr-1"
                            />
                          </span>
                          <span *ngIf="+group.total_price < +group.order_price">
                            {{
                              "remaining_amount_for_discount_shipping"
                                | translate
                                  : {
                                      remaining_amount:
                                        +group.order_price - +group.total_price,
                                      currency: productService?.Currency.name
                                    }
                            }}
                          </span>
                        </p>

                        <img
                          *ngIf="group.img"
                          class="my-2 d-block"
                          [src]="Common._ImageUrl + 'groups/' + group.img"
                          alt="Shipping image"
                          width="auto"
                          height="20"
                          (error)="handleImageError($event)"
                        />
                      </div>
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          [style.width.%]="
                            +group.total_price > +group.order_price
                              ? 100
                              : (+group.total_price / +group.order_price) * 100
                          "
                        ></div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div *ngIf="cart_products?.length == 0 && !loading">
      <div class="text-center col-sm-12 empty-cart-cls">
        <img src="assets/images/icon-empty-cart.png" class="mb-4 img-fluid" />
        <h3>
          <strong>{{ "Your Cart is Empty" | translate }}</strong>
        </h3>
        <div class="row wishlist-buttons">
          <div class="text-center col-12">
            <a [routerLink]="['/']" class="btn btn-solid">{{
              "continue shopping" | translate
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--section end-->

<!-- DELETE INVOICE MODAL -->
<ng-template class="theme-modal" #newsletter let-modal>
  <div class="modal-content">
    <div class="modal-body modal7" style="background-image: unset">
      <div class="p-0 container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="modal-bg">
              <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="offer-content" *ngIf="step1">
                <div class="circle-icon">
                  <i class="ti-info-alt"></i>
                </div>
                <h2>{{ "Delete" | translate }}</h2>
                <h4>
                  {{ "Do you want to cancel the order number" | translate }}
                  {{ cart_products[0]["invoice_edit_id"] }}
                </h4>
                <br />

                <div class="modal-footer">
                  <button
                    type="button"
                    class="mr-auto btn btn-primary"
                    (click)="cancelInvoice(cart_products[0]['invoice_edit_id'])"
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    aria-label="Close"
                    (click)="modal.dismiss('Cross click')"
                  >
                    No
                  </button>
                </div>
              </div>

              <div class="offer-content" *ngIf="step2">
                <h2>{{ "Delete" | translate }}</h2>

                <div class="category-image">
                  <img
                    src="assets/images/slider/loader12.gif"
                    alt=""
                    class="w-auto"
                  />
                </div>

                <br />
                <div class="modal-footer">
                  <br />
                  <br />
                </div>
              </div>

              <div class="offer-content" *ngIf="step3">
                <div class="circle-icon-check">
                  <i class="fa fa-check-circle" aria-hidden="true"></i>
                </div>

                <h2 class="text-success">
                  {{ "Delete Order Successfully" | translate }}
                </h2>

                <br />
                <div class="modal-footer">
                  <br />
                  <button
                    type="button"
                    class="mr-auto btn btn-primary"
                    aria-label="Close"
                    (click)="modal.dismiss('Cross click')"
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #notifyModal let-modal>
  <app-notify-me-modal
    [productIdToListenToAvailability]="productIdToListenToAvailability"
  ></app-notify-me-modal>
</ng-template>
<section #login class="full-sidebar" *ngIf="loginclicked">
  <div class="shadow login-sidebar">
    <i class="ti-close login-icon" (click)="close()"></i>
    <app-fast-login
      (step5Reached)="onStep5Reached()"
      (stepSuccessReached)="onstepSuccessReached()"
    ></app-fast-login>
  </div>
</section>

<!-- overflow text -->
<ng-template class="theme-modal" let-modal #overflowTextModal>
  <div
    class="d-flex justify-content-between align-items-center note_title"
    style="border-bottom: 1px solid #eee; background: #fff; margin-inline: 10px"
  >
    <h4 class="p-2 m-0">{{ selectedAttachmentTitle }}</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="p-2 text-center">
    {{ selectedAttachment }}
  </div>
</ng-template>
